import RolePermissionForm from "components/config/role/permission/form";
import { Alert } from "components/core";
import PageHeader from "components/PageHeader";
import { isNullEmptyOrWhitespace } from "helpers/common";
import { useParams } from "react-router-dom";

const ConfigRolePermissionMutatePage = () => {
  const { roleId, id } = useParams();

  if (isNullEmptyOrWhitespace(roleId)) {
    return <Alert theme="danger">Role ID is required.</Alert>;
  }

  const isNewPermission = isNullEmptyOrWhitespace(id);

  return (
    <>
      <PageHeader>
        <div className="flex flex-row justify-between">
          <div className="text-xl">
            {isNewPermission ? "Create" : "Edit"} Permission
          </div>
        </div>
      </PageHeader>
      <div className="col-span-full">
        <RolePermissionForm roleId={roleId} permissionId={id} />
      </div>
    </>
  );
};

export default ConfigRolePermissionMutatePage;
