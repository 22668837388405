import Card, { CardTitle } from "components/Card";
import Dashboard from "components/Dashboard";
import RecentVisits from "components/RecentVisits";
import UsefulLinks from "components/UsefulLinks";
import { AppDataContext } from "context/AppDataProvider";
import { useUser } from "hooks/useUser";
import { useContext, useEffect, useState } from "react";
import { useDashboardGetByMenuId } from "hooks/useDashboard";
import ListSkeleton from "components/core/Lists/ListSkeleton";
import { Alert } from "components/core";
import { useFarmGetMany } from "hooks/useFarm";
import { useActiveMenuItem } from "hooks/useMenu";

interface HomeProps {
  className?: string;
}

export default function Home({ className }: HomeProps) {
  const { setPageTitle } = useContext(AppDataContext);
  const { user } = useUser();
  const { data: farms } = useFarmGetMany();
  const activeMenuItem = useActiveMenuItem("main");

  const userFarmGroups =
    farms?.reduce((acc, farm) => {
      const farmGroup = farm.FarmGroup.toLowerCase();

      if (!acc.includes(farmGroup)) {
        acc.push(farmGroup);
      }

      return acc;
    }, [] as string[]) ?? [];

  const {
    isLoading: isLoadingDashboard,
    error: errorDashboard,
    dashboards,
  } = useDashboardGetByMenuId({
    enabled: !!activeMenuItem?.id,
    id: activeMenuItem?.id,
  });

  const [quoteOfTheDay, setQuoteOfTheDay] = useState<string>("");

  /**
   * Mount/unmount
   */
  useEffect(() => {
    function getInspirationalQuote() {
      const quotes = [
        '"Doing little things well is a step toward doing big things better." - Vincent Van Gogh',
        '"It has never been more important to control what is within our control." - Alan Gibson',
        '"Success is no accident." - Chris McCoubrey',
        '"The real risk is doing nothing." - Denis Waitley',
        "\"Success isn't always about greatness. It's about consistency.\" - A. Wallace",
      ];

      return quotes[Math.floor(Math.random() * quotes.length)];
    }

    setQuoteOfTheDay(getInspirationalQuote());
  }, []);

  /**
   * Set page title
   */
  useEffect(() => {
    if (user?.fullname !== undefined) {
      setPageTitle(`Welcome ${user.fullname}!`);
    }
  }, [setPageTitle, user?.fullname]);

  const dashboard = dashboards.find((d) => {
    const farmGroups = d?.farmGroups?.toLowerCase().split(",");

    return farmGroups?.some((farmGroup) => userFarmGroups.includes(farmGroup));
  });

  return (
    <main className="flex flex-col flex-grow p-4">
      <div className="grid grid-cols-1 gap-4 tablet:grid-cols-2">
        <div className="col-span-full">
          <Card>
            <div className="text-2xl font-medium mb-2">
              Welcome
              {user?.fullname !== undefined ? ` ${user.fullname}!` : "..."}
            </div>
            <p className="text-gray-600 text-sm">{quoteOfTheDay}</p>
          </Card>
        </div>

        <div>
          <Card>
            <CardTitle>Useful links</CardTitle>
            <UsefulLinks key="useful-links" className="-mx-4" />
          </Card>
        </div>

        <div>
          <Card>
            <CardTitle>Recent visits</CardTitle>
            <RecentVisits key="recent-visits" className="-mx-4" />
          </Card>
        </div>

        {isLoadingDashboard ? <ListSkeleton /> : null}

        {errorDashboard ? (
          <Alert theme="danger">
            <p className="text-sm">{errorDashboard}</p>
          </Alert>
        ) : null}

        {!!dashboard && (
          <div className="col-span-full">
            <Dashboard dashboard={dashboard} />
          </div>
        )}
      </div>
    </main>
  );
}
