import Card from "components/Card";
import RoleForm from "components/config/role/form";
import PageHeader from "components/PageHeader";
import { cn, isNullEmptyOrWhitespace } from "helpers/common";
import { Link, useParams } from "react-router-dom";

const ConfigRoleMutatePage = () => {
  const { id } = useParams();

  return (
    <>
      <PageHeader>
        <div className="text-xl">Create Role</div>
      </PageHeader>
      <div className="col-span-full">
        <RoleForm id={id} />
        <PermissionsButton id={id} />
        <MenuPermissionsButton id={id} />
      </div>
    </>
  );
};

export default ConfigRoleMutatePage;

type PermissionsButtonProps = {
  id: string | undefined;
};
function PermissionsButton({ id }: PermissionsButtonProps) {
  if (isNullEmptyOrWhitespace(id)) {
    return null;
  }

  return (
    <Card>
      <fieldset className="mb-4 space-y-4">
        <Legend
          className="mb-4"
          title="Permission(s)"
          description="Assign user role permissions."
        />

        <Link
          className="text-primary hover:text-primary-darker"
          to="permissions"
        >
          Edit Permission(s)
        </Link>
      </fieldset>
    </Card>
  );
}

type MenuPermissionsButtonProps = {
  id: string | undefined;
};
function MenuPermissionsButton({ id }: MenuPermissionsButtonProps) {
  if (isNullEmptyOrWhitespace(id)) {
    return null;
  }

  return (
    <Card>
      <fieldset className="mb-4 space-y-4">
        <Legend
          className="mb-4"
          title="Menu(s)"
          description="Assign user role menu permissions."
        />

        <Link className="text-primary hover:text-primary-darker" to="menus">
          Edit Menu Permission(s)
        </Link>
      </fieldset>
    </Card>
  );
}

type LegendProps = {
  title: string;
  description: string;
  className?: string;
};
const Legend: React.FC<LegendProps> = ({ title, description, className }) => {
  return (
    <legend className={cn(className)}>
      <h2 className="font-semibold text-base leading-7 text-gray-900">
        {title}
      </h2>
      <p className="mt-0 text-xs leading-6 text-gray-500">{description}</p>
    </legend>
  );
};
