import Card, { CardSkeleton } from "components/Card";
import { useMenuGetAll } from "hooks/useMenu";
import { Link } from "react-router-dom";

type MenuListProps = {
  roleId: string;
};
export default function MenuList({ roleId }: MenuListProps) {
  const { isLoading, error, data: menus } = useMenuGetAll();

  if (isLoading) {
    return <CardSkeleton />;
  }

  if (error) {
    return <Card>{error}</Card>;
  }

  return (
    <Card>
      <div className="">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="font-semibold py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 tablet:pl-0"
              >
                Menu
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 tablet:pr-0">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {menus.map((menu) => (
              <tr key={menu.id}>
                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 tablet:w-auto tablet:max-w-none tablet:pl-0">
                  <Link to={`${menu.id}`}>{menu.name}</Link>
                </td>
                <td className="py-4 pl-3 pr-4 text-right text-sm font-medium tablet:pr-0">
                  <Link
                    to={`${menu.id}`}
                    className="text-primary hover:text-primary-dark"
                  >
                    Edit<span className="sr-only">, {menu.name}</span>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
}
