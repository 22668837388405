import { useParams } from "react-router-dom";
import UserForm from "components/config/user/form";
import PageHeader from "components/PageHeader";
// import UserProfile from "~/components/config/user/profile";

const UserFormPage = () => {
  const { id } = useParams();

  if (!id) {
    // render an error or a loading state
    return null;
  }

  return (
    <>
      <PageHeader>
        <div className="flex flex-row justify-between">
          <div className="text-xl">User profile</div>
        </div>
      </PageHeader>
      <div className="col-span-full">
        <UserForm />
      </div>
    </>
  );
};

export default UserFormPage;
