import PageHeader from "components/PageHeader";
import Menu from "components/Menu";
import { useEffect } from "react";

const ConfigPage = () => {
  useEffect(() => {
    // check user permissions here in session
  }, []);

  return (
    <>
      <PageHeader className="">
        <div className="text-xl">Configuration</div>
      </PageHeader>
      <Menu menuName="config" />
    </>
  );
};

export default ConfigPage;
