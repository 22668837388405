import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "components/placement/Form";
import Button from "components/placement/Button";
import DynamicField from "./dynamic_field";
import {
  evaluateCalculation,
  generateFormSchema,
  isFieldVisible,
} from "./dynamic_form_util";
import { DynamicFormDef } from "./types";
import { ErrorMessage } from "@hookform/error-message";
import { cn } from "helpers/common";
import DynamicFieldVisibilityChecker from "./visibility_checker";
import Card from "components/Card";

type DynamicFormProps = {
  className?: string;
  formDefinition: DynamicFormDef;
  defaultValues: Record<string, any> | null;
  onCancel?: () => void;
  onSave?: (data: Record<string, any>) => void;
};
const DynamicForm: React.FC<DynamicFormProps> = ({
  className,
  formDefinition,
  defaultValues,
  onCancel,
  onSave,
}) => {
  const { fields } = formDefinition;

  const form = useForm({
    resolver: async (values, context, options) => {
      // Generate the schema based on current values
      const schema = generateFormSchema(fields, values);

      // Use zodResolver to validate the form values against the schema
      return zodResolver(schema)(values, context, options);
    },
    defaultValues: defaultValues || {},
    mode: "onChange",
  });
  const { setValue: formSetValue } = form;

  // Watch all fields
  const fieldValues = useWatch({ control: form.control });

  // Handle calculated fields
  React.useEffect(() => {
    fields.forEach((field) => {
      if (field.calculation) {
        try {
          // Evaluate the calculation
          const result = evaluateCalculation(field.calculation, fieldValues);
          formSetValue(field.name, result);
        } catch (error) {
          console.error(`Error in calculation for field ${field.name}:`, error);
        }
      }
    });
  }, [fieldValues, fields, formSetValue]);

  const onSubmit = (data: Record<string, any>) => {
    // Remove hidden fields from data
    const visibleData = Object.keys(data).reduce((acc, key) => {
      const field = fields.find((f) => f.name === key);
      if (field && isFieldVisible(field, fieldValues)) {
        acc[key] = data[key];
      }
      return acc;
    }, {} as Record<string, any>);

    onSave?.(visibleData);
  };

  const handleClickCancel = () => {
    form.reset();
    // parent callback
    onCancel?.();
  };

  return (
    <Form {...form}>
      <form
        className={cn(className, "mt-4 space-y-4")}
        onSubmit={form.handleSubmit(onSubmit)}
        noValidate
      >
        <Card>
          {fields
            .sort((a, b) => a.order - b.order)
            .map((fieldDef) => (
              <DynamicFieldVisibilityChecker
                key={fieldDef.id}
                field={fieldDef}
                values={fieldValues}
              >
                <DynamicField
                  key={fieldDef.id}
                  form={form}
                  fieldDef={fieldDef}
                />
              </DynamicFieldVisibilityChecker>
            ))}
        </Card>
        <ErrorMessage
          errors={form.formState.errors}
          name="form"
          render={({ message }) => (
            <div className="text-danger-500 text-sm">{message}</div>
          )}
        />
        <div className="flex justify-between">
          <Button
            type="button"
            variant="default"
            size="sm"
            onClick={handleClickCancel}
          >
            Cancel
          </Button>
          <Button type="submit" variant="primary" size="sm">
            Submit
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default DynamicForm;
