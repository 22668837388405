import { useCallback, useContext } from "react";
import { NavContext } from "context/NavProvider";
import { BreadcrumbItem } from "components/core/Breadcrumbs/Breadcrumb";
import { isNull, isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { useNavigate } from "react-router-dom";
import { dateToString } from "helpers/dateUtilities";
import useDeepCompareEffect from "use-deep-compare-effect";

export default function BreadcrumbItemHouse({
  houseId,
  house,
  houses,
  ...other
}) {
  const { setOptionsToHide } = useContext(NavContext);
  const navigate = useNavigate();

  //#region Callbacks

  const selectHouse = useCallback(
    (houseId) => {
      const searchParams = new URLSearchParams(
        window.location.search.substring(1)
      );
      searchParams.set("houseId", houseId);
      navigate({
        search: "?" + searchParams.toString(),
      });
    },
    [navigate]
  );

  //#endregion

  //#region Side-effects

  /**
   * Auto-set house
   */
  useDeepCompareEffect(() => {
    if (!isNullEmptyOrWhitespace(houseId)) return;

    if (isNull(house) && houses.length === 1) {
      // when house is not set and only a single house exists
      selectHouse(houses[0].HouseNumber);
      // Prevent house dropdown from opening
      setOptionsToHide("house");
    }
  }, [houseId, house, houses, selectHouse, setOptionsToHide]);

  //#endregion

  //#region Event handlers

  const handleClickBreadcrumbHouse = (houseId) => {
    selectHouse(houseId);
  };

  //#endregion

  return (
    <BreadcrumbItem
      {...other}
      title={house?.HouseNumber ? `House ${house?.HouseNumber}` : "Houses"}
      subtitle={
        house
          ? house.Pens[0].Placement?._DatePlaced?.normalised
            ? `Placed on ${dateToString(
                house.Pens[0].Placement._DatePlaced.normalised
              )}`
            : "No placement"
          : "Select house"
      }
      showDivider={false}
      loaded={houses.length > 0}
      options={houses?.map((h) => ({
        id: h.HouseNumber,
        text: (
          <div className="font-medium text-primary mr-3">
            House {h.HouseNumber}
          </div>
        ),
        onClick: () => handleClickBreadcrumbHouse(h.HouseNumber),
      }))}
      optionsHeading={<div className="text-lg font-medium">House</div>}
    />
  );
}
