import { useMenuGetOneByName } from "hooks/useMenu";
import { buildListPageUrl } from "helpers/redirectUtilities";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { List, ListItem } from "./core";
import ListSkeleton from "./core/Lists/ListSkeleton";
import { MenuItem } from "context/MenuProvider";

interface Props {
  className?: string;
}

export default function UsefulLinks({ className }: Props) {
  const { data: menu } = useMenuGetOneByName({ name: "main" });
  const navigate = useNavigate();

  const topMenuItems = useMemo(() => {
    let result: MenuItem[] = [];
    let counter = 0;
    const maxItems = 3;

    for (const parentMenuItem of menu?.menuItems ?? []) {
      if (counter >= maxItems) {
        break;
      }
      if (parentMenuItem.children === undefined) {
        continue;
      }
      const children = parentMenuItem.children.filter((c) => c.href !== "/");
      result.push({
        ...parentMenuItem,
        children: children.slice(0, maxItems - counter),
      });
      counter += children.length;
    }

    return result;
  }, [menu]);

  const handleOnClickUsefulLinks = (childMenu: MenuItem) => {
    return navigate(buildListPageUrl(childMenu));
  };

  return topMenuItems.length > 0 ? (
    <List size="small" theme="striped" className={className}>
      {topMenuItems.map((parentMenu) => {
        if (parentMenu.children === undefined) {
          return null;
        }

        return parentMenu.children.map((menu) => (
          <ListItem onClick={() => handleOnClickUsefulLinks(menu)}>
            <span>{parentMenu.title}</span>{" "}
            <span className="text-gray-300">&nbsp;/&nbsp;</span>{" "}
            <span className="text-primary">{menu.title}</span>
          </ListItem>
        ));
      })}
    </List>
  ) : (
    <ListSkeleton />
  );
}
