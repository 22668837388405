import { BreadcrumbItem } from "components/core/Breadcrumbs/Breadcrumb";
import { buildListPageUrl } from "helpers/redirectUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { useFarmGetMany } from "hooks/useFarm";
import { useActiveMenuItem } from "hooks/useMenu";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export default function BreadcrumbItemMenu({ farm, ...other }) {
  const navigate = useNavigate();
  const activeMenuItem = useActiveMenuItem("main");

  const { data: farms } = useFarmGetMany();

  const distinctFarmGroupCount = useMemo(() => {
    const farmGroupsSet = new Set();
    for (const farm of farms) {
      const farmGroup = farm.FarmGroup?.toLowerCase();
      if (farmGroup) {
        farmGroupsSet.add(farmGroup);
      }
    }

    return farmGroupsSet.size;
  }, [farms]);

  const menuFilteredByMeta = useMemo(() => {
    let result = {};

    if (!isNullEmptyOrWhitespace(farm?.FarmGroup)) {
      result["Group"] = [farm.FarmGroup];
    }

    return result;
  }, [farm]);

  //#region Side effects

  //#endregion

  //#region Event handlers

  const handleClickMenu = (menu) => {
    return navigate(buildListPageUrl(menu));

    // TODO: trigger refresh of form values for formType in URL
  };

  //#endregion

  if (activeMenuItem?.Metadata?.breadcrumb?.showMenu === false) {
    return null;
  }

  return (
    <BreadcrumbItem
      {...other}
      title={activeMenuItem?.title}
      subtitle="Menu"
      showDivider={false}
      loaded={!!activeMenuItem?.parent?.children}
      options={
        !!activeMenuItem?.parent?.children?.length
          ? activeMenuItem.parent.children.map((m) => {
              const meta = {};
              if (!isNullEmptyOrWhitespace(m.parsedMeta?.farmgroups)) {
                meta.Group = m.parsedMeta.farmgroups;
              }

              return {
                id: m.id,
                text: (
                  <div className="font-medium text-primary mr-3">{m.title}</div>
                ),
                onClick: () => handleClickMenu(m),
                meta,
              };
            })
          : []
      }
      optionsHeading={<div className="text-lg font-medium">Menu</div>}
      optionsFilteredByMeta={
        distinctFarmGroupCount > 1 ? menuFilteredByMeta : null
      }
    />
  );
}
