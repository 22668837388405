import React, { createContext, useMemo } from "react";
import { useUser } from "hooks/useUser";
import useFetch from "hooks/useFetch";
import { protectedResources } from "authConfig";
import { isNullEmptyOrWhitespace } from "helpers/common";
import { z } from "zod";

const menuItemBaseSchema = z.object({
  id: z.string(),
  title: z.string(),
  href: z.string(),
  order: z.number(),
  parentId: z.string().optional(),
  permissionId: z.string().optional(),
  menuId: z.string(),
  meta: z.string().optional(),
});

const menuItemSchema = menuItemBaseSchema.extend({
  children: z
    .array(
      menuItemBaseSchema.extend({
        children: z.array(menuItemBaseSchema).optional(),
      })
    )
    .optional(),
});

export type MenuItem = z.infer<typeof menuItemSchema> & {
  children?: MenuItem[];
};

export const menuSchema = z.object({
  id: z.string(),
  name: z.string(),
  isLegacy: z.boolean(),
  menuItems: z.array(menuItemSchema).optional(),
});

export type Menu = z.infer<typeof menuSchema>;

export interface IMenusContext {
  data: Menu[];
  isLoading: boolean;
  isFetched: boolean;
  error: string | null;
}

const MenusContext = createContext({} as IMenusContext);

const MenuProvider = ({ children }: { children: React.ReactNode }) => {
  const { user, isSignedIn } = useUser();
  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
  });
  const [data, setData] = React.useState<Menu[]>([]);

  const fetchData = React.useCallback(async () => {
    if (isNullEmptyOrWhitespace(user)) {
      return;
    }

    const { data } = await execute("GET", "/api/menus-get");

    const menus = data?.d ?? [];
    const result = z.array(menuSchema).safeParse(menus);
    if (!result.success) {
      console.error("Error parsing menu items", result.error);
      return;
    }

    setData(result.data);

    return result.data;
  }, [execute, user]);

  React.useEffect(() => {
    if (isSignedIn) {
      fetchData();
    }
  }, [fetchData, isSignedIn]);

  const contextValue = useMemo(
    () => ({
      data,
      isLoading,
      isFetched,
      error,
      refetch: fetchData,
    }),
    [data, isLoading, isFetched, error, fetchData]
  );

  return (
    <MenusContext.Provider value={contextValue}>
      {children}
    </MenusContext.Provider>
  );
};

export { MenuProvider as default, MenusContext };
