import { useEffect, useCallback } from "react";
import { BreadcrumbItem } from "components/core/Breadcrumbs/Breadcrumb";
import { isNull, isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { useNavigate } from "react-router-dom";
import { buildListPageUrl } from "helpers/redirectUtilities";
import { useActiveMenuItem } from "hooks/useMenu";

export default function BreadcrumbItemFarm({ farmId, farm, farms, ...other }) {
  const navigate = useNavigate();
  const activeMenuItem = useActiveMenuItem("main");

  //#region Callbacks

  const selectFarm = useCallback(
    (farm) => {
      const redirectURL = buildListPageUrl(activeMenuItem);

      const newSearchParams = new URLSearchParams(
        window.location.search?.substring(1)
      );
      newSearchParams.set("farmId", farm.FarmCode);

      const _selectedHouseId = newSearchParams.get("houseId");
      if (!isNullEmptyOrWhitespace(_selectedHouseId)) {
        const _existsSelectedHouse = farm.Houses.some(
          (house) => house.HouseNumber.toString() === _selectedHouseId
        );
        if (!_existsSelectedHouse) {
          newSearchParams.delete("houseId");
        }
      }

      redirectURL.search = newSearchParams.toString();

      return navigate(redirectURL);
    },
    [navigate, activeMenuItem]
  );

  const farmFilteredByMeta = useCallback(() => {
    let result = {};
    if (
      !isNullEmptyOrWhitespace(activeMenuItem?.parsedMeta?.display?.farmgroups)
    ) {
      result["Group"] = activeMenuItem?.parsedMeta?.display?.farmgroups.map(
        (fg) => fg.toUpperCase()
      );
    }

    return result;
  }, [activeMenuItem?.parsedMeta?.display?.farmgroups]);

  //#endregion

  //#region Side-effects

  /**
   * Auto-set farm
   */
  useEffect(() => {
    if (!isNullEmptyOrWhitespace(farmId)) return;

    if (isNull(farm) && farms.length === 1) {
      // when farm is not set and only a single farm exists
      selectFarm(farms[0]);
    }
  }, [farmId, farm, farms, selectFarm]);

  //#endregion

  //#region Event handlers

  const handleClickBreadcrumbFarm = (farm) => {
    selectFarm(farm);
  };

  //#endregion

  if (activeMenuItem?.parsedMeta?.breadcrumb?.showfarm === false) {
    return null;
  }

  return (
    <BreadcrumbItem
      {...other}
      title={farm?.FarmName ?? "Farms"}
      subtitle={farm ? `${farm?.FarmGroup}, ${farm?.FarmCode}` : "Select farm"}
      showDivider={false}
      loaded={farms.length > 0}
      options={farms?.map((f) => ({
        id: f.FarmCode,
        text: (
          <div>
            <p className="font-medium text-primary">{f.FarmName}</p>
            <p className="text-xs text-gray-600">{f.FarmCode}</p>
          </div>
        ),
        onClick: () => handleClickBreadcrumbFarm(f),
        avatarText: f.FarmGroup,
        meta: { Group: f.FarmGroup },
      }))}
      optionsFilteredByMeta={farmFilteredByMeta()}
      optionsHeading={<div className="text-lg font-medium">Farm</div>}
    />
  );
}
