import Card, { CardTitle } from "components/Card";
import { Alert } from "components/core";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";
import Button from "components/placement/Button";
import {
  rolePermissionsMutateSchema,
  RolePermissionsMutateSchema,
  useRoleGetOne,
  useRolePermissionsMutate,
} from "hooks/useRole";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Form } from "components/placement/Form";
import { useForm, UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useMenuGetOne } from "hooks/useMenu";
import { Checkbox } from "components/placement/Checkbox";
import { useUser } from "hooks/useUser";
import { type CheckedState } from "@radix-ui/react-checkbox";
import { isNullEmptyOrWhitespace } from "helpers/common";
import { type MenuItem } from "context/MenuProvider";

type RoleMenuFormProps = {
  roleId: string;
  menuId: string;
};
const RoleForm = ({ roleId, menuId }: RoleMenuFormProps) => {
  const navigate = useNavigate();
  const { user } = useUser();

  const {
    data: roleData,
    isLoading: isLoadingRoles,
    isFetched: isFetchedRoles,
  } = useRoleGetOne({
    id: roleId,
  });

  const {
    data: menuData,
    isLoading: isLoadingMenus,
    isFetched: isFetchedMenus,
  } = useMenuGetOne({
    enabled: !isNullEmptyOrWhitespace(user),
    id: menuId,
  });

  const { mutate } = useRolePermissionsMutate({
    onSuccess: () => {
      toast.success("Role menu permissions updated successfully.");
    },
    onError: (errMessage) => {
      toast.error(errMessage);
    },
  });

  const reactForm = useForm<RolePermissionsMutateSchema>({
    defaultValues: {
      id: roleId,
      permissions: {} as RolePermissionsMutateSchema["permissions"],
    },
    resolver: zodResolver(rolePermissionsMutateSchema),
  });
  const resetForm = reactForm.reset;

  const availablePermissions = React.useMemo(() => {
    return collectPermissionIds(menuData?.menuItems ?? []);
  }, [menuData]);

  React.useEffect(() => {
    if (roleData) {
      const newFormData = {
        id: roleData.id,
        // { permissionId1: true, permissionId2: true }
        permissions: availablePermissions.reduce(
          (acc: Record<string, boolean>, permissionId) => {
            acc[permissionId] = roleData.permissions.some(
              (p) => p.id === permissionId
            );
            return acc;
          },
          {}
        ),
      };
      resetForm(newFormData);
    }
  }, [roleData, resetForm, availablePermissions]);

  if (isLoadingRoles || isLoadingMenus || !isFetchedRoles || !isFetchedMenus) {
    return <FieldsetSkeleton />;
  }

  if (!roleData) {
    return <Alert theme="danger">Role not found.</Alert>;
  }

  if (!menuData) {
    return <Alert theme="danger">Menu not found.</Alert>;
  }

  const onSubmit = async (data: RolePermissionsMutateSchema) => {
    console.log("data", data);
    await mutate(data);
  };

  const handleClickCancel = () => {
    navigate("/admin/config/roles");
  };

  return (
    <>
      <Form {...reactForm}>
        <form
          className="mt-4 space-y-4"
          onSubmit={reactForm.handleSubmit(onSubmit)}
          noValidate
        >
          <Card>
            <CardTitle>
              <div>
                Edit Menu Permission(s) for role{" "}
                <span className="italic">{roleData.name}</span>
              </div>
            </CardTitle>
            <nav>
              {!!menuData.menuItems && (
                <MenuBuilder
                  reactForm={reactForm}
                  menuItems={menuData.menuItems}
                />
              )}
            </nav>
          </Card>
          <div className="flex justify-between">
            <Button
              type="button"
              variant="default"
              size="sm"
              onClick={handleClickCancel}
            >
              Cancel
            </Button>
            <div className="space-x-4">
              <Button type="submit" variant="primary">
                Save
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </>
  );
};

export default RoleForm;

const MenuBuilder: React.FC<{
  reactForm: UseFormReturn<RolePermissionsMutateSchema>;
  menuItems: MenuItem[];
}> = ({ reactForm, menuItems }) => {
  const formPermissions = reactForm.watch("permissions"); // Watch for changes in permissions

  const handleGroupCheckboxChange = (
    checked: CheckedState,
    parentMenuId: string
  ) => {
    const parentMenuItem = menuItems.find((item) => item.id === parentMenuId);
    if (!parentMenuItem) {
      return;
    }

    const permissionIds = collectPermissionIds(parentMenuItem.children ?? []);
    const newPermissions = Object.assign({}, formPermissions);
    for (const id of permissionIds) {
      if (checked === "indeterminate") {
        newPermissions[id] = true;
      } else {
        newPermissions[id] = checked;
      }
    }

    reactForm.setValue("permissions", newPermissions, {
      shouldValidate: true,
    });
  };

  const handlePermissionCheckboxChange = (
    checked: CheckedState,
    permissionId: string
  ) => {
    const newPermissions = { ...formPermissions }; // Ensure we are not mutating state directly
    if (checked) {
      newPermissions[permissionId] = true;
    } else {
      newPermissions[permissionId] = false;
    }

    reactForm.setValue("permissions", newPermissions, {
      shouldValidate: true,
    });
  };

  const getCountCheckedPermissionsInGroup = (menuItems: MenuItem[]): number => {
    return menuItems.reduce((count, item) => {
      if (item.permissionId && formPermissions[item.permissionId] === true) {
        return count + 1;
      }
      if (item.children) {
        return count + getCountCheckedPermissionsInGroup(item.children);
      }
      return count;
    }, 0);
  };

  if (!menuItems) {
    return null;
  }

  return (
    <ul className="space-y-4">
      {menuItems.map((item) => {
        const countCheckedPermissionsInGroup = item.children
          ? getCountCheckedPermissionsInGroup(item.children)
          : 0;
        const totalPermissionsInGroup = item.children
          ? collectPermissionIds(item.children).length
          : 0;

        // const countCheckedPermissionsInGroup = item.children
        //   ? item.children.filter(
        //       (item) =>
        //         !item.permissionId ||
        //         selectedPermissionsIds.includes(item.permissionId)
        //     ).length
        //   : 0;
        return (
          <li key={item.id}>
            {item.children && item.children.length ? (
              <div className="space-y-4">
                <div className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                  <Checkbox
                    checked={
                      countCheckedPermissionsInGroup === totalPermissionsInGroup
                        ? true
                        : countCheckedPermissionsInGroup > 0
                        ? "indeterminate"
                        : false
                    }
                    onCheckedChange={(checked) =>
                      handleGroupCheckboxChange(checked, item.id)
                    }
                  />
                  <div className="space-y-1 leading-none">{item.title}</div>
                </div>
                <ul className="mt-1 pl-6 pr-2">
                  <MenuBuilder
                    reactForm={reactForm}
                    menuItems={item.children}
                  />
                </ul>
              </div>
            ) : (
              <div className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                <Checkbox
                  checked={
                    !!item.permissionId &&
                    formPermissions[item.permissionId] === true
                  } // Use watched permissions
                  onCheckedChange={(checked) => {
                    if (!item.permissionId) {
                      return;
                    }

                    handlePermissionCheckboxChange(checked, item.permissionId);
                  }}
                />
                <div className="space-y-1 leading-none">
                  {item.title} - {item.meta}
                </div>
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};

function collectPermissionIds(menuItems: MenuItem[]): string[] {
  return menuItems.flatMap((item) => {
    const childPermissions = item.children
      ? collectPermissionIds(item.children)
      : [];
    return item.permissionId
      ? [item.permissionId, ...childPermissions]
      : childPermissions;
  });
}
