import { useContext, useState, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import Historical from "components/Historical";
import { useNavigate } from "react-router-dom";
import { AppDataContext } from "context/AppDataProvider";
import useQuery from "hooks/useQuery";
import ListSkeleton from "components/core/Lists/ListSkeleton";
import { buildFormPageUrl } from "helpers/redirectUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { deepClone } from "helpers/dataUtilities";
import Card from "components/Card";
import { IFarm, calcGenericFormDataDates } from "helpers/farmUtilities";
import { IForm, IFormData } from "helpers/formUtilities";
import Dashboard from "components/Dashboard";
// import FarmSelector from "components/FarmSelector";
// import HouseSelector from "components/HouseSelector";
// import MenuSelector from "components/MenuSelector";
import Breadcrumb from "components/Breadcrumb";
import Tabs from "components/Tabs";
import { useDashboardGetByMenuId } from "hooks/useDashboard";
import { useFormDataGetManyByFormType } from "hooks/useFormData";
import { localDateToSQLDate } from "helpers/dateUtilities";
import { useFarmGetMany } from "hooks/useFarm";
import { useFormGetMany } from "hooks/useForm";
import ButtonNew from "components/Historical/ButtonNew";
import DatePickerWithRange from "components/placement/DatePickerRange";
import Button from "components/placement/Button";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Form, FormField, FormItem } from "components/placement/Form";
import type { DateRange } from "react-day-picker";
import ButtonSkeleton from "components/core/Buttons/ButtonSkeleton";
import { useActiveMenuItem } from "hooks/useMenu";

export default function ListPage() {
  let location = useLocation();
  let navigate = useNavigate();
  const { module, moduleFeatureGroup } = useParams();

  const activeMenuItem = useActiveMenuItem("main");

  let query = useQuery();
  const farmId = query.get("farmId");
  const houseId = query.get("houseId");

  const { data: farms } = useFarmGetMany();
  const { data: forms } = useFormGetMany();

  const farm = farms.find((f: IFarm) =>
    !isNullEmptyOrWhitespace(farmId)
      ? f.FarmCode.toLowerCase() === farmId!.toLowerCase()
      : false
  );

  // const house = !isNullEmptyOrWhitespace(houseId) && !!farm ? getFarmHouse(farm!, parseInt(houseId!)) : undefined;

  // const placement = !!house ? getPlacementFromHouse(house, 1) : undefined;

  // const datePlaced = placement?._DatePlaced?.normalised;

  // const { fromDate, toDate, numDaysSincePlacement } = datePlaced
  //   ? calcProductionFormDataDates(datePlaced)
  //   : {
  //       fromDate: undefined,
  //       toDate: undefined,
  //       numDaysSincePlacement: undefined,
  //     };

  const formDates = calcGenericFormDataDates();
  const [fromDate, setFromDate] = useState<Date | undefined>(
    formDates.fromDate
  );
  const [toDate, setToDate] = useState<Date | undefined>(formDates.toDate);

  const { pageTitle, setPageTitle } = useContext(AppDataContext);

  const isFormDatasEnabled =
    !isNullEmptyOrWhitespace(farmId) &&
    !isNullEmptyOrWhitespace(houseId) &&
    !isNullEmptyOrWhitespace(moduleFeatureGroup) &&
    !isNullEmptyOrWhitespace(module) &&
    !isNullEmptyOrWhitespace(fromDate) &&
    !isNullEmptyOrWhitespace(toDate);
  const {
    data,
    isLoading: isFormDatasLoading,
    refetch: refetchFormDatas,
  } = useFormDataGetManyByFormType({
    enabled: isFormDatasEnabled,
    farmId: farmId ?? "",
    houseId: parseInt(houseId ?? "0"),
    formType: moduleFeatureGroup?.toLowerCase() ?? "",
    moduleId: module ?? "",
    fromDate: localDateToSQLDate(fromDate) ?? "",
    toDate: localDateToSQLDate(toDate) ?? "",
  });

  const [sortedData, setSortedData] = useState<IFormData[]>(data);

  const [currentView, setCurrentView] = useState<string | undefined>(undefined);

  const [isLoading, setIsLoading] = useState(true);

  const userFarmGroups =
    farms?.reduce((acc, farm) => {
      const farmGroup = farm.FarmGroup.toLowerCase();

      if (!acc.includes(farmGroup)) {
        acc.push(farmGroup);
      }

      return acc;
    }, [] as string[]) ?? [];

  const { dashboards } = useDashboardGetByMenuId({
    enabled: !!activeMenuItem?.id,
    id: activeMenuItem?.id,
  });

  const dashboard = dashboards.find((d) => {
    const farmGroups = d?.farmGroups?.toLowerCase().split(",");

    return farmGroups?.some((farmGroup) => userFarmGroups.includes(farmGroup));
  });

  const filteredForms = useMemo(() => {
    let filteredForms = forms.filter(
      (f) =>
        f.FormType?.toLowerCase() === moduleFeatureGroup?.toLowerCase() &&
        f.ModuleName?.toLowerCase() === module?.toLowerCase()
    );

    const formsClone = deepClone(filteredForms) as IForm[];

    formsClone.forEach((f) => {
      // Filter form fields by FarmType
      if (farm?.FarmType) {
        f.FormFields = f.FormFields?.filter(
          (ff) =>
            isNullEmptyOrWhitespace(ff.FarmType) ||
            ff.FarmType?.split(",").some(
              (ft) => ft.toLowerCase() === farm.FarmType.toLowerCase()
            )
        );
      }

      // Filter form fields by FarmGroup
      if (farm?.FarmGroup) {
        f.FormFields = f.FormFields?.filter(
          (ff) =>
            isNullEmptyOrWhitespace(ff.FarmGroup) ||
            ff.FarmGroup?.split(",").some(
              (fg) => fg.toLowerCase() === farm.FarmGroup.toLowerCase()
            )
        );
      }
    });

    return formsClone;
  }, [forms, module, moduleFeatureGroup, farm?.FarmType, farm?.FarmGroup]);

  /**
   * Set page title
   */
  useEffect(() => {
    setPageTitle(activeMenuItem?.title ?? "");
  }, [location.pathname, setPageTitle, activeMenuItem?.title]);

  useEffect(() => {
    if (isFormDatasLoading || !isFormDatasEnabled) {
      setIsLoading(true);
      return;
    }

    const newSortedData = data?.sort((a, b) => {
      const aDate = a._DateApplies?.normalised?.getTime() ?? 0;
      const bDate = b._DateApplies?.normalised?.getTime() ?? 0;

      return bDate - aDate;
    });

    setSortedData(newSortedData ?? data);

    setIsLoading(false);
  }, [data, isFormDatasEnabled, isFormDatasLoading]);

  //#endregion

  //#region Listeners

  function handleButtonNewClick(formType: string, formName: string) {
    return navigate(buildFormPageUrl(activeMenuItem, formName));
  }

  //#endregion

  return (
    <main className="flex flex-col flex-grow overflow-x-hidden">
      <div className="relative z-20 bg-white border-b border-gray-100">
        <Breadcrumb showHome={false} farmRequired={true} houseRequired={true} />
      </div>
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <div className="flex flex-row items-center">
            <div className="text-lg text-gray-600 uppercase flex-grow font-medium">
              {pageTitle}
            </div>
            <div className="flex space-x-2">
              {isLoading ? (
                <>
                  <ButtonSkeleton className="h-10 w-20" />
                  <ButtonSkeleton className="h-10 w-20" />
                </>
              ) : (
                <>
                  <LoadMoreButton
                    fromDate={fromDate}
                    toDate={toDate}
                    onLoadMore={(values) => {
                      setFromDate(values.dateRange.from);
                      setToDate(values.dateRange.to);
                      refetchFormDatas({
                        cacheResponse: false,
                        fromDate:
                          localDateToSQLDate(values.dateRange.from) ?? "",
                        toDate: localDateToSQLDate(values.dateRange.to) ?? "",
                      });
                    }}
                  />
                  <ButtonNew
                    className="justify-end"
                    onClickOption={handleButtonNewClick}
                    options={filteredForms
                      .filter(
                        (f) =>
                          f.Permissions.includes("view") &&
                          f.Permissions.includes("create") &&
                          f.FormType?.toLowerCase() ===
                            moduleFeatureGroup?.toLowerCase()
                      )
                      .map((f) => ({
                        FormType: f.FormType ?? "",
                        FormName: f.FormName ?? "",
                        FormTitle: f.FormTitle ?? "",
                      }))}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        {!!dashboard && farmId !== null && houseId !== null && (
          <div className="col-span-full">
            <Tabs
              tabs={[
                {
                  name: "List",
                  id: "list",
                  current: currentView === undefined || currentView === "list",
                },
                {
                  name: "Dashboard",
                  id: "dashboard",
                  current: currentView === "dashboard",
                },
              ]}
              onChange={(id: string) => setCurrentView(id)}
            ></Tabs>
          </div>
        )}

        {(currentView === "list" || currentView === undefined) && (
          <div className="col-span-full">
            <Card>
              {isLoading ? (
                <ListSkeleton />
              ) : (
                <div className="flex flex-col flex-grow space-y-4">
                  <Historical
                    className="-mx-4"
                    farmId={farmId?.toLowerCase() ?? ""}
                    houseId={houseId?.toString() ?? ""}
                    forms={filteredForms.filter((f) =>
                      f.Permissions.includes("view")
                    )}
                    items={sortedData}
                  />
                </div>
              )}
            </Card>
          </div>
        )}

        {currentView === "dashboard" && !!dashboard && (
          <div className="col-span-full">
            <Dashboard dashboard={dashboard} />
          </div>
        )}
      </div>
    </main>
  );
}

const loadMoreFormSchema = z.object({
  dateRange: z.object({
    from: z.date().optional(),
    to: z.date().optional(),
  }),
});

const defaultDateRange = {
  dateRange: {
    from: new Date(),
    to: new Date(),
  },
};

const LoadMoreButton: React.FC<{
  fromDate: Date | undefined;
  toDate: Date | undefined;
  onLoadMore: (values: z.infer<typeof loadMoreFormSchema>) => void;
}> = ({ fromDate, toDate, onLoadMore }) => {
  const form = useForm<z.infer<typeof loadMoreFormSchema>>({
    resolver: zodResolver(loadMoreFormSchema),
    defaultValues: defaultDateRange,
  });
  const { reset } = form;

  const onSubmit = (values: z.infer<typeof loadMoreFormSchema>) => {
    onLoadMore(values);
  };

  useEffect(() => {
    reset({
      dateRange: {
        from: fromDate,
        to: toDate,
      },
    });
  }, [fromDate, toDate, reset]);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="relative flex flex-grow items-stretch border border-gray-300 rounded-md"
      >
        <FormField
          control={form.control}
          name="dateRange"
          render={({ field }) => (
            <FormItem>
              <DatePickerWithRange
                className="border-0 rounded-r-none ring-0 ring-offset-0 m-0 h-full"
                buttonSize="sm"
                selected={field.value as DateRange}
                onSelect={field.onChange}
              />
            </FormItem>
          )}
        />
        <Button
          className="h-full border-gray-300 border-0 border-l border-l-gray-300 rounded-l-none"
          size="sm"
          type="submit"
        >
          Filter
        </Button>
      </form>
    </Form>
  );
};
