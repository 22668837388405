import {
  Alert,
  Button,
  ButtonClicked,
  Fieldset,
  Select,
} from "components/core";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";
import FormField from "components/forms/FormField";
import PageHeader from "components/PageHeader";
import { DATA_STATUS } from "constants.js";
import {
  IForm,
  IFormField,
  getFormDataStatus,
  IFormValue,
  IFormValueDataSources,
  getPenDataFromFormData,
  IFormData,
  IPenFormValid,
} from "helpers/formUtilities";
import useQuery from "hooks/useQuery";
import { useEffect, useMemo, useState, useRef } from "react";
import { FileDown } from "lucide-react";
import { buildFormValues } from "helpers/formsUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { useReportDownload } from "hooks/useReports";
import { useFarmGetOne } from "hooks/useFarm";
import { toast } from "react-toastify";
import { useFormGetMany } from "hooks/useForm";
import { useParams } from "react-router-dom";

export default function ReportForm() {
  let query = useQuery();
  let farmId = query.get("farmId");
  const { module, moduleFeatureGroup } = useParams();

  const [selectedFormId, setSelectedFormId] = useState<string>("");
  const [formValues, setFormValues] = useState<IFormData | undefined>(
    undefined
  );
  const [formValid, setFormValid] = useState<IPenFormValid[]>([]);
  const [isProcessingButtonVisible, setIsProcessingButtonVisible] =
    useState<Boolean>(false);

  const { isLoading: isLoadingForms, data: forms } = useFormGetMany();
  const farm = useFarmGetOne(farmId ?? "");

  const { mutate: downloadReport } = useReportDownload({
    onSuccess: () => {
      toast.success("Report downloaded successfully");
    },
    onError: (errMessage) => {
      toast.error(
        errMessage ??
          "An error occurred while fetching the report. Please try again. If the problem persists, please contact our support team."
      );
      setIsProcessingButtonVisible(false);
    },
  });

  const abortControllerRef = useRef(undefined) as any;

  const filteredForms = useMemo<IForm[]>(
    () =>
      forms.filter(
        (f: IForm) =>
          f.FormType?.toLowerCase() === moduleFeatureGroup?.toLowerCase() &&
          f.ModuleName?.toLowerCase() === module?.toLowerCase()
      ),
    [forms, moduleFeatureGroup, module]
  );

  const selectedForm = useMemo<IForm | undefined>(
    () =>
      filteredForms?.find(
        (f: { FormName: string }) => f.FormName === selectedFormId
      ),
    [filteredForms, selectedFormId]
  );

  const dataStatus = useMemo<Number | undefined>(
    () => getFormDataStatus(formValid),
    [formValid]
  );

  /**
   * Mount/Unmount
   */
  useEffect(
    () => {
      abortControllerRef.current = new AbortController();

      return () => {
        abortControllerRef.current.abort();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (isLoadingForms) {
    return <FieldsetSkeleton />;
  }

  async function handleOnSubmit(fileFormat = "pdf") {
    // Prevent submission when dataStatus is NOT complete or draft
    if (dataStatus !== DATA_STATUS.COMPLETE) {
      toast.error("Please fill out all required fields");

      return;
    }

    toast.info("Downloading report...");

    setIsProcessingButtonVisible(true);

    const allowedFormFieldRefs = selectedForm?.FormFields?.map(
      (f: IFormField) => f.Ref?.toLowerCase()
    );

    const _reportParams =
      formValues?.PenValues?.[0].Values?.reduce(
        (acc: { [key: string]: any }, curr) => {
          if (allowedFormFieldRefs?.includes(curr.Ref?.toLowerCase())) {
            acc[curr.Ref] = curr.Value;
          }
          return acc;
        },
        {}
      ) ?? {};

    await downloadReport(selectedFormId, _reportParams, fileFormat);

    setIsProcessingButtonVisible(false);
  }

  const handleSetFieldValue = (
    field: IFormField,
    penId: string,
    formValue: IFormValue
  ) => {
    if (!field?.Ref || !penId)
      return new Error("'ref' and 'pen' are required properties.");

    setFormValues((prevState) => {
      const dataSources: IFormValueDataSources = {
        this: prevState ?? {},
      };

      try {
        const newState = buildFormValues(
          field,
          penId,
          formValue,
          selectedForm,
          dataSources,
          undefined,
          undefined,
          0,
          undefined
        );

        return newState;
      } catch (error) {
        console.error(error);
      }

      return prevState as any;
    });
  };

  function handleSetReportId(id: any) {
    setSelectedFormId(id);
  }

  const handleSetFieldValid = (
    fieldId: string, // We use the id here because the field.Ref is not unique. (e.g. when using repeater fields `field.Ref_1`)
    field: IFormField,
    penNumber: string,
    valid: boolean,
    complete: boolean,
    required: boolean
  ) => {
    setFormValid((prevState) => {
      const newFormValid = prevState.filter(
        (fv) =>
          !(
            fv.Ref === fieldId &&
            (isNullEmptyOrWhitespace(field.QuestionGroup) ||
              fv.QuestionGroup === field.QuestionGroup) &&
            fv.Pen.toString() === penNumber.toString()
          )
      );
      newFormValid.push({
        Ref: fieldId,
        QuestionGroup: field.QuestionGroup,
        Pen: penNumber,
        Valid: valid,
        Complete: complete,
        Required: required,
      });

      return newFormValid;
    });
  };

  return (
    <div className="flex-grow overflow-x-hidden">
      <div className="relative z-20">
        {/* <Breadcrumb showHome={false} menuRequired={false} farmRequired={true} /> */}
        <PageHeader className="py-6 px-4 sm:px-6 lg:px-8">
          <div className="text-xl">Reports</div>
        </PageHeader>
      </div>
      <main className="flex flex-col flex-grow">
        <div className="flex flex-col flex-grow w-full max-w-7xl mx-auto py-6 px-6 desktop:px-8">
          {!navigator.onLine ? (
            <Alert theme="warning" className="mb-6">
              You are offline. Please connect to the internet to download
              reports.
            </Alert>
          ) : filteredForms.length > 0 ? (
            <>
              <div className="flex flex-col flex-grow">
                <Fieldset title="Reports" text={undefined} content={undefined}>
                  <div className="space-y-4">
                    <Select
                      label="Report"
                      listOptions={filteredForms.map(
                        (form: { FormTitle: string; FormName: string }) => ({
                          Id: form.FormName,
                          Text: form.FormTitle,
                          Value: form.FormName,
                        })
                      )}
                      id="report"
                      hint={undefined}
                      value={selectedFormId}
                      setValue={handleSetReportId}
                      labelPosition="inset"
                    />
                    {selectedForm &&
                      selectedForm.FormFields.map((ff) => (
                        <FormField
                          key={ff.Ref}
                          {...{
                            id: ff.Ref,
                            field: ff,
                            penNumber: "1",
                            farm,
                            setFieldValue: handleSetFieldValue,
                            setFieldValid: handleSetFieldValid,
                            formValues: getPenDataFromFormData("1", formValues),
                          }}
                        />
                      ))}
                  </div>
                </Fieldset>
              </div>
              <div className="flex space-x-4 mt-4 tablet:justify-end">
                {isProcessingButtonVisible ? (
                  <ButtonClicked />
                ) : (
                  <>
                    <Button
                      isFullWidth={undefined}
                      theme="primary"
                      label={undefined}
                      onClick={undefined}
                      disabled={undefined}
                      icon={
                        <FileDown className="h-5 w-5 mr-1 text-primary-lighter" />
                      }
                      iconPosition="left"
                      optionsHeading={undefined}
                      optionProps={undefined}
                      showOptions={undefined}
                      onShowOptions={undefined}
                      onHideOptions={undefined}
                      showStats={undefined}
                      showSearch={undefined}
                      optionsFilteredByMeta={undefined}
                      type="submit"
                      options={[
                        {
                          id: "pdf",
                          text: "PDF",
                          onClick: () => handleOnSubmit("pdf"),
                        },
                        {
                          id: "excel",
                          text: "Excel",
                          onClick: () => handleOnSubmit("excel"),
                        },
                      ]}
                    >
                      Download
                    </Button>
                  </>
                )}
              </div>
            </>
          ) : (
            <FieldsetSkeleton />
          )}
        </div>
      </main>
    </div>
  );
}
