import React from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  type Permission,
  type RoleWithPermissions,
  rolePermissionsMutateSchema,
  RolePermissionsMutateSchema,
  usePermissionGetAll,
  useRolePermissionDelete,
  useRolePermissionsMutate,
} from "hooks/useRole";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Alert } from "components/core";
import Card, { CardTitle } from "components/Card";
import Button from "components/placement/Button";
import { Form } from "components/placement/Form";
import { groupObjectsBy } from "helpers/dataUtilities";
import { Checkbox } from "components/placement/Checkbox";
import { type CheckedState } from "@radix-ui/react-checkbox";
import { Link } from "react-router-dom";
import { tryInvalidateApiCache } from "helpers/common";

type RolePermissionListProps = {
  role: RoleWithPermissions;
};
const RolePermissionList: React.FC<RolePermissionListProps> = ({
  role: roleData,
}) => {
  const navigate = useNavigate();

  const {
    data: permissionsData,
    isLoading: isLoadingPermissions,
    isFetched: isFetchedPermissions,
    refetch: refetchPermissions,
  } = usePermissionGetAll();

  const permissionByGroup = React.useMemo(() => {
    return groupObjectsBy(permissionsData, "group") as Record<
      string,
      Permission[]
    >;
  }, [permissionsData]);

  const { mutate } = useRolePermissionsMutate({
    onSuccess: () => {
      toast.success("Permissions updated successfully.");
    },
    onError: (errMessage) => {
      toast.error(errMessage);
    },
  });

  const { mutate: deletePermissionMutate } = useRolePermissionDelete({
    onSuccess: () => {
      toast.success("Permission deleted successfully.");

      tryInvalidateApiCache("/api/permissions-get");
      refetchPermissions();
    },
    onError: (errMessage) => {
      toast.error(errMessage);
    },
  });

  const reactForm = useForm<RolePermissionsMutateSchema>({
    defaultValues: {
      id: roleData.id,
      permissions: {} as RolePermissionsMutateSchema["permissions"],
    },
    resolver: zodResolver(rolePermissionsMutateSchema),
  });
  const resetForm = reactForm.reset;

  React.useEffect(() => {
    if (roleData) {
      const newFormData = {
        id: roleData.id,
        // { permissionId1: true, permissionId2: true }
        permissions: permissionsData.reduce(
          (acc: Record<string, boolean>, item) => {
            acc[item.id] = roleData.permissions.some((p) => p.id === item.id);
            return acc;
          },
          {}
        ),
      };
      resetForm(newFormData);
    }
  }, [roleData, resetForm, permissionsData]);

  if (isLoadingPermissions || !isFetchedPermissions) {
    return <FieldsetSkeleton />;
  }

  if (!permissionsData) {
    return <Alert theme="danger">Permissions not found.</Alert>;
  }

  const onSubmit = async (data: RolePermissionsMutateSchema) => {
    console.log("data", data);
    await mutate(data);
  };

  const handleClickCancel = () => {
    navigate("/admin/config/roles");
  };

  const handleGroupCheckboxChange = (
    checked: CheckedState,
    groupId: string
  ) => {
    const permissions = permissionsData.filter(
      (item) => item.group === groupId
    );
    const formPermissions = reactForm.getValues("permissions");

    const permissionIds = permissions.map((item) => item.id);
    const newPermissions = Object.assign({}, formPermissions);
    for (const id of permissionIds) {
      if (checked === "indeterminate") {
        newPermissions[id] = true;
      } else {
        newPermissions[id] = checked;
      }
    }

    reactForm.setValue("permissions", newPermissions, { shouldValidate: true });
  };

  const handlePermissionCheckboxChange = (
    checked: CheckedState,
    permissionId: string
  ) => {
    const formPermissions = reactForm.getValues("permissions");
    const newPermissions = Object.assign({}, formPermissions);
    if (checked) {
      newPermissions[permissionId] = true;
    } else {
      newPermissions[permissionId] = false;
    }

    reactForm.setValue("permissions", newPermissions, { shouldValidate: true });
  };

  const handleClickDeletePermission = (id: string) => {
    deletePermissionMutate(id);
  };

  const getCountCheckedPermissionsInGroup = (permissions: Permission[]) => {
    return permissions.reduce((count, item) => {
      if (item.id && reactForm.getValues("permissions")[item.id] === true) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  const formPermissions = reactForm.watch("permissions");

  return (
    <Form {...reactForm}>
      <form
        className="mt-4 space-y-4"
        onSubmit={reactForm.handleSubmit(onSubmit)}
        noValidate
      >
        <Card>
          <CardTitle className="mb-4">Update permissions for role.</CardTitle>
          <nav>
            <ul className="space-y-4">
              {Object.entries(permissionByGroup).map(
                ([groupId, group], index) => {
                  // const countCheckedPermissionsInGroup = group.filter((item) =>
                  //   Object.keys(formPermissions).includes(item.id)
                  // ).length;
                  const countCheckedPermissionsInGroup =
                    getCountCheckedPermissionsInGroup(group);

                  return (
                    <li key={groupId}>
                      <div className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                        <Checkbox
                          checked={
                            countCheckedPermissionsInGroup === group.length
                              ? true
                              : countCheckedPermissionsInGroup > 0
                              ? "indeterminate"
                              : false
                          }
                          onCheckedChange={(checked) =>
                            handleGroupCheckboxChange(checked, groupId)
                          }
                        />
                        <div className="space-y-1 leading-none">{groupId}</div>
                      </div>
                      <ul className="mt-1 pl-6 pr-2">
                        {group.map((item) => (
                          <li key={item.id}>
                            <div className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                              <Checkbox
                                checked={formPermissions[item.id] === true}
                                onCheckedChange={(checked) =>
                                  handlePermissionCheckboxChange(
                                    checked,
                                    item.id
                                  )
                                }
                              />
                              <div className="space-y-1 leading-none">
                                {item.name}
                              </div>
                              <div className="grow" />
                              <div className="inline-flex space-x-2">
                                {item.group === "legacypage" && (
                                  <span className="text-gray-300 text-xs">
                                    Mapped to ey_zsgm.
                                  </span>
                                )}
                                {item.group !== "legacypage" && (
                                  <>
                                    <Link
                                      to={`/admin/config/roles/${roleData.id}/permissions/${item.id}`}
                                      className="text-primary text-sm"
                                    >
                                      Edit
                                    </Link>
                                    <Link
                                      to="#"
                                      className="text-primary text-sm"
                                      onClick={() =>
                                        handleClickDeletePermission(item.id)
                                      }
                                    >
                                      Delete
                                    </Link>
                                  </>
                                )}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </li>
                  );
                }
              )}
            </ul>
          </nav>
        </Card>
        <div className="flex justify-between">
          <Button
            type="button"
            variant="default"
            size="sm"
            onClick={handleClickCancel}
          >
            Cancel
          </Button>
          <div className="space-x-4">
            <Button type="submit" variant="primary">
              Save
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};

export default RolePermissionList;
