import * as React from "react";
import { cn } from "helpers/common";
import { cva, VariantProps } from "class-variance-authority";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const textareaVariants = cva(
  "flex min-h-[80px] w-full rounded-md border border-gray-300 text-gray-900 px-3 py-2 text-sm placeholder:text-gray-400 focus:border-gray-300 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 focus-visible:ring-offset-white disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "",
        ghost: "bg-transparent border-0 border-transparent",
      },
      customSize: {
        default: "py-2 px-3 text-sm leading-5",
        sm: "py-1 px-2 text-xs leading-4",
        xs: "py-0.5 px-1 text-xs leading-4",
        lg: "py-3 px-4 text-base leading-6",
      },
    },
    compoundVariants: [
      {
        variant: "default",
        customSize: ["default", "lg"],
        class: "rounded-md",
      },
      {
        variant: "default",
        customSize: ["sm", "xs"],
        class: "rounded-sm",
      },
    ],
    defaultVariants: {
      variant: "default",
      customSize: "default",
    },
  }
);

const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  React.TextareaHTMLAttributes<HTMLTextAreaElement> &
    VariantProps<typeof textareaVariants>
>(({ className, variant, customSize, ...props }, ref) => {
  return (
    <textarea
      className={cn(textareaVariants({ variant, customSize }), className)}
      ref={ref}
      {...props}
    />
  );
});
Textarea.displayName = "Textarea";

export { Textarea };
