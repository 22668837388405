import { isNullEmptyOrWhitespace } from "helpers/common";
import { Alert } from "./core";
import FieldsetSkeleton from "./core/Forms/FieldsetSkeleton";

export const LoadingOrError = ({
  isReady,
  error,
  children,
}: {
  isReady: boolean;
  error: string;
  children: React.ReactNode;
}) => {
  if (!isReady) {
    return <FieldsetSkeleton />;
  }

  if (!isNullEmptyOrWhitespace(error)) {
    return <Alert theme="danger">{error}</Alert>;
  }

  return <>{children}</>;
};
