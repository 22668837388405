import { PlusIcon } from "lucide-react";
import Button from "./placement/Button";

type EmptyStateProps = {
  name?: string;
  onClickNew?: () => void;
};
export default function EmptyState(
  { name, onClickNew }: EmptyStateProps = {
    name: "Record",
    onClickNew: () => {},
  }
) {
  return (
    <div className="text-center">
      <svg
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        aria-hidden="true"
        className="mx-auto h-12 w-12 text-gray-400"
      >
        <path
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          strokeWidth={2}
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No projects</h3>
      <p className="mt-1 text-sm text-gray-500">
        Get started by creating a new {name?.toLowerCase()}.
      </p>
      <div className="mt-6">
        <Button type="button" variant="primary" onClick={onClickNew}>
          <PlusIcon className="mr-0.5 h-5 w-5" /> New {name}
        </Button>
      </div>
    </div>
  );
}
