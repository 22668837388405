import Logo from "assets/Logo";
import classNames from "classnames";
import Slideover from "./core/Slideover";
import { useUser } from "hooks/useUser";
import { generateInitialsFromText } from "helpers/stringUtilities";
import { InitialsAvatar } from "./core";
import { useSidebar } from "context/SidebarProvider";
import useNetworkStatus from "hooks/useNetworkStatus";
import Menu from "./Menu";

export interface ISidebarProps {
  position?: "left" | "right";
  theme?: "light" | "dark";
  setTheme?: (theme: "light" | "dark") => void;
  setShowSettings: (showSettings: boolean) => void;
}

export default function Sidebar(props: ISidebarProps) {
  const { isOnline } = useNetworkStatus();
  const { user } = useUser();
  const { sidebarOpen, setSidebarOpen } = useSidebar();

  // const handleShowSettings = () => {
  //   props.setShowSettings(true);
  //   setSidebarOpen(false);
  // };

  return (
    <Slideover
      title={
        <Logo className="mt-4 dark:text-white" style={{ height: "44px" }} />
      }
      className={classNames(props.theme, "text-white z-50")}
      open={sidebarOpen}
      setOpen={setSidebarOpen}
      position={props.position}
    >
      <div
        data-cy="sidebar"
        className={classNames("flex flex-col flex-grow overflow-y-auto")}
      >
        <div className="flex-grow flex flex-col">
          <nav className="flex-1" aria-label="Sidebar">
            <Menu
              menuName="main"
              onClickMenuItem={() => {
                setSidebarOpen(false);
              }}
            />
          </nav>
        </div>
      </div>
      <div className="shrink-0 flex border-t border-black/20 bg-black/10 dark:border-gray-900 dark:bg-gray-800 dark:bg-black/20 p-4">
        <div className="shrink-0 w-full group block">
          <div className="flex items-center">
            <div>
              <button
                className={`flex text-sm rounded-full outline-none ring-2 ring-offset-1 ring-offset-primary-darkest2 dark:ring-offset-gray-800 relative ${
                  !isOnline ? "ring-gray-500" : "ring-success-500"
                }`}
                id="user-profile"
              >
                {user && (
                  <InitialsAvatar
                    initials={generateInitialsFromText(
                      `${(user as any).FullName}`
                    )}
                  />
                )}
                <span className="flex h-3 w-3 absolute -right-1 bottom-0">
                  {isOnline && (
                    <span
                      className={`animate-ping absolute inline-flex h-full w-full rounded-full bg-success-500`}
                    ></span>
                  )}
                  <span
                    className={`relative inline-flex rounded-full h-3 w-3 ${
                      !isOnline ? "bg-gray-500" : "bg-success-500"
                    } ring-1 ring-primary-darkest2 dark:ring-gray-800`}
                  ></span>
                </span>
              </button>
            </div>
            <div className="ml-4 flex-grow">
              {(user as any)?.FullName ? (
                <>
                  <p className="text-sm font-medium">
                    {(user as any).FullName}
                  </p>
                  <p className="text-xs">{(user as any).UserName}</p>
                </>
              ) : null}
            </div>
            {/* <div
              className="cursor-pointer dark:text-gray-300 hover:text-primary dark:hover:text-primary"
              onClick={() => handleShowSettings()}
            >
              <div className="sr-only">Settings</div>
              <CogIcon className="h-5 w-5" />
            </div> */}
          </div>
        </div>
      </div>
    </Slideover>
  );
}
