import MenuList from "components/config/role/menu/list";
import PageHeader from "components/PageHeader";
import Button from "components/placement/Button";
import { isNullEmptyOrWhitespace } from "helpers/common";
import { PlusCircleIcon } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

const ConfigRoleMenuPage = () => {
  const { roleId } = useParams();
  const navigate = useNavigate();

  if (isNullEmptyOrWhitespace(roleId)) {
    return null;
  }

  return (
    <>
      <PageHeader>
        <div className="flex flex-row justify-between">
          <div className="text-xl">Roles &amp; Permissions</div>
          <Button
            type="button"
            variant="primary"
            onClick={() => navigate("new")}
          >
            <PlusCircleIcon className="mr-0.5 h-5 w-5" /> Create New
          </Button>
        </div>
      </PageHeader>
      <div className="col-span-full">
        <MenuList roleId={roleId} />
      </div>
    </>
  );
};

export default ConfigRoleMenuPage;
