import classNames from "classnames";
import { Disclosure } from "@headlessui/react";
import { useUser } from "hooks/useUser";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "lucide-react";
import { useMenuGetOneByName } from "hooks/useMenu";
import { isNullEmptyOrWhitespace } from "helpers/common";
import { MenuItem } from "context/MenuProvider";

type MenuProps = {
  menuName: string;
  onClickMenuItem?: () => void;
};

const Menu: React.FC<MenuProps> = ({ menuName, onClickMenuItem }) => {
  const { user } = useUser();
  const { data: menu, isLoading: isLoadingMenu } = useMenuGetOneByName({
    enabled:
      !isNullEmptyOrWhitespace(user) && !isNullEmptyOrWhitespace(menuName),
    name: menuName,
  });

  if (isLoadingMenu) return <div>Loading menu...</div>;

  if (!menu || !menu.menuItems) return <div>Menu not found</div>;

  if (!user) return <div>User not found</div>;

  // function isCurrent(item: (typeof menu.menuItems)[0]): boolean {
  //   if (item.href === window.location.pathname) {
  //     return true;
  //   } else if (item.children && item.children.length > 0) {
  //     return item.children.some((child) => isCurrent(child));
  //   }

  //   return false;
  // }

  return (
    <nav>{buildMenu(menu.menuItems, user?.username, onClickMenuItem)}</nav>
  );
};

export default Menu;

function saveRecentVisit(userId: string, menuId: string) {
  if (!userId || !menuId) {
    return;
  }

  const recentVisits = localStorage.getItem(`rv-${userId}`);
  const recentVisitsJson = JSON.parse(recentVisits || "[]") as string[];
  const recentVisitsUpdated = recentVisitsJson
    .filter((r: string) => r !== menuId)
    .slice(0, 3);
  recentVisitsUpdated.unshift(menuId);
  localStorage.setItem(`rv-${userId}`, JSON.stringify(recentVisitsUpdated));
}

function buildMenu(
  items: MenuItem[],
  userName: string,
  onClickMenuItem?: () => void
) {
  return (
    <ul>
      {items.map((item) => {
        const children = item.children;
        return (
          <li key={item.id}>
            {children ? (
              <Disclosure as="div" className="group">
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={classNames(
                        // isCurrent(item) ? "bg-gray-50" : "hover:bg-gray-50",
                        "font-semibold flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm leading-6 group-hover:text-primary"
                      )}
                    >
                      <ChevronRightIcon
                        className={classNames(
                          open ? "rotate-90" : "",
                          "h-5 w-5 shrink-0 group-hover:text-primary"
                        )}
                        aria-hidden="true"
                      />
                      {item.title}
                    </Disclosure.Button>
                    <Disclosure.Panel as="ul" className="mt-1 px-2">
                      {buildMenu(children, userName, onClickMenuItem)}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ) : (
              <Link
                to={item.href}
                className={classNames(
                  // isCurrent(item) ? "bg-gray-50" : "hover:bg-gray-50",
                  "font-semibold block rounded-md py-2 pl-10 pr-2 text-sm leading-6 hover:text-primary"
                )}
                onClick={() => {
                  userName && saveRecentVisit(userName, item.id);
                  onClickMenuItem && onClickMenuItem();
                }}
              >
                {item.title}
              </Link>
            )}
          </li>
        );
      })}
    </ul>
  );
}
