import React, { useContext, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Datepicker,
  MultiSelect,
  TableChart,
} from "components/core";
import { AppDataContext } from "context/AppDataProvider";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import classNames from "classnames";
import { XIcon } from "lucide-react";
import InfiniteScroll from "react-infinite-scroll-component";
import { AUDIT_STATUS } from "constants.js";
import DescriptionListSkeleton from "components/core/Lists/DescriptionListSkeleton";
import { buildFormPageUrl } from "helpers/redirectUtilities";
import Breadcrumb from "components/Breadcrumb";
import Card from "components/Card";
import SectionTitle from "components/SectionTitle";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";
import { useNcnGetMany, useNcnFilterOptions } from "hooks/useNcn";
import { useFarmGetMany } from "hooks/useFarm";
import { useActiveMenuItem } from "hooks/useMenu";

const limit = 25;

const contentTypeMap = new Map([
  // ["due", "date"],
  ["audit date", "date"],
  ["severity", "status"],
  ["audit score", "number"],
  ["max score", "number"],
]);

const _disallowedKeys = [
  "id",
  "key",
  "onclick",
  "ncnstatus",
  "parentquestionid",
  "ncnseverity",
  "ncnpwaid",
  "auditstatus",
  "ncnformname",
  "auditformname",
  "modulename",
  "auditpwaid",
  "auditformname",
];

const statusListOptions = Object.entries(AUDIT_STATUS).map(([key, value]) => {
  return {
    Id: value,
    Text: key,
    Value: value,
  };
});

const ListPage = () => {
  const navigate = useNavigate();
  const { forms } = useContext(AppDataContext);
  const { data: farms } = useFarmGetMany();
  const activeMenuItem = useActiveMenuItem("main");

  const prevFiltersStr = sessionStorage.getItem("ncnFilters");
  const prevFilters = JSON.parse(prevFiltersStr ?? "{}");

  const [queryStatus, setQueryStatus] = React.useState(
    prevFilters?.data?.queryStatus ?? AUDIT_STATUS.OPEN
  );
  const [queryFieldStaff, setQueryFieldStaff] = React.useState(
    prevFilters?.data?.queryFieldStaff ?? ""
  );
  const [queryFarmCode, setQueryFarmCode] = React.useState(
    prevFilters?.data?.queryFarmCode ?? ""
  );
  const [queryAuditDateFrom, setQueryAuditDateFrom] = React.useState(
    prevFilters?.data?.queryAuditDateFrom ?? ""
  );
  const [queryAuditDateTo, setQueryAuditDateTo] = React.useState(
    prevFilters?.data?.queryAuditDateTo ?? ""
  );
  const [queryAuditType, setQueryAuditType] = React.useState(
    prevFilters?.data?.queryAuditType ?? ""
  );

  const orderByRef = useRef(prevFilters?.meta?.order ?? "");
  const orderByDirectionRef = useRef(prevFilters?.meta?.orderByDirection ?? "");

  const {
    isLoading: isLoadingNcnFilterOpts,
    error: errorNcnFilterOpts,
    data: ncnFilterOpts,
  } = useNcnFilterOptions();

  const {
    isLoading: isLoadingNcns,
    error: errorNcns,
    data: ncnData,
    loadMore: loadMoreNcns,
    hasMore: hasMoreNcns,
    refetch: refetchNcns,
  } = useNcnGetMany({
    queryStatus,
    queryFieldStaff,
    queryFarmCode,
    queryAuditDateFrom,
    queryAuditDateTo,
    queryAuditType,
    limit,
    orderBy: orderByRef.current,
    orderByDirection: orderByDirectionRef.current,
  });

  const tableKeys = React.useMemo(() => {
    if (!ncnData?.[0]) return [];

    return Object.keys(ncnData[0])
      .filter((key) => !_disallowedKeys.includes(key.toLowerCase()))
      .map((key) => {
        return {
          id: key,
          title: key,
          type: contentTypeMap.get(key.toLowerCase()) ?? "string",
        };
      });
  }, [ncnData]);

  const formListOptions = React.useMemo(() => {
    if (!forms) return [];

    return forms
      .filter(
        (form) =>
          form.FormType.toLowerCase() === "audit" &&
          !form.FormName.toLowerCase().startsWith("ncn_")
      )
      .map((form) => {
        return {
          Id: form.FormName,
          Text: form.FormTitle,
          Value: form.FormName,
        };
      });
  }, [forms]);

  const handleSubmit = (e) => {
    e.preventDefault();

    /**
     * Save to session storage for retrieve upon page refresh/return
     */
    sessionStorage.setItem(
      "ncnFilters",
      JSON.stringify({
        meta: {
          // page: currentPageRef.current, // Best not use this with infinite scroll
          order: orderByRef.current,
          orderByDirection: orderByDirectionRef.current,
        },
        data: {
          queryStatus,
          queryFieldStaff,
          queryFarmCode,
          queryAuditDateFrom,
          queryAuditDateTo,
          queryAuditType,
        },
      })
    );

    refetchNcns();
  };

  const handleSortTable = (key, direction) => {
    orderByRef.current = key;
    orderByDirectionRef.current = direction;
    refetchNcns();
  };

  const handleOnClickRow = (item) => {
    return navigate(
      buildFormPageUrl(
        activeMenuItem,
        item.NCNFormName,
        item.AuditPWAID,
        item.AuditFormName,
        item.id,
        undefined,
        {
          farmId: item["Farm Code"],
          houseId: item.House?.toString() ?? "1",
        }
      )
    );
  };

  const fieldStaffListOptions = useMemo(
    () =>
      ncnFilterOpts
        ?.filter((lo) => lo.ListName?.toLowerCase() === "fieldstaffregion")
        ?.map((lo) => ({
          Id: lo.Id,
          Text: lo.Text,
          Value: lo.Id,
        })) ?? [],
    [ncnFilterOpts]
  );

  const farmListOptions = useMemo(
    () =>
      farms.map((f) => ({
        Id: f.FarmCode,
        Text: `${f.FarmCode} - ${f.FarmName}`,
        Value: f.FarmCode,
      })) ?? [],
    [farms]
  );

  return (
    <main className="flex flex-col flex-grow min-h-full">
      <div className="relative z-20 bg-white border-b border-gray-100">
        <Breadcrumb
          key="breadcrumb"
          showHome={false}
          showFarm={false}
          farmRequired={false}
          houseRequired={false}
        />
      </div>
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <SectionTitle>Audit Non-Conformances</SectionTitle>
        </div>
        <div className="col-span-full">
          <Card>
            {isLoadingNcnFilterOpts ? (
              <FieldsetSkeleton />
            ) : errorNcnFilterOpts ? (
              <Alert theme="danger">
                An error occured while loading the filters. Please refresh and
                try again. If the issue continues please contact the technical
                support team.
              </Alert>
            ) : (
              <Filters
                queryStatus={queryStatus}
                setQueryStatus={setQueryStatus}
                queryFieldStaff={queryFieldStaff}
                setQueryFieldStaff={setQueryFieldStaff}
                queryFarmCode={queryFarmCode}
                setQueryFarmCode={setQueryFarmCode}
                queryAuditDateFrom={queryAuditDateFrom}
                setQueryAuditDateFrom={setQueryAuditDateFrom}
                queryAuditDateTo={queryAuditDateTo}
                setQueryAuditDateTo={setQueryAuditDateTo}
                statusListOptions={statusListOptions}
                fieldStaffListOptions={fieldStaffListOptions}
                farmListOptions={farmListOptions}
                formListOptions={formListOptions}
                setQueryAuditType={setQueryAuditType}
                queryAuditType={queryAuditType}
                onSubmit={handleSubmit}
              />
            )}
          </Card>
        </div>
        <div className="col-span-full">
          <Card>
            {!isLoadingNcns && errorNcns ? (
              <Alert theme="danger">
                An error occured while loading the records. Please refresh and
                try again. If the issue continues please contact the technical
                support team.
              </Alert>
            ) : !isLoadingNcns && !ncnData ? (
              <Alert theme="info">
                No records found. Please try again with different filters.
              </Alert>
            ) : (
              <InfiniteScroll
                className="infinite-scroll"
                style={{ overflow: "visible" }}
                dataLength={ncnData.length}
                next={loadMoreNcns}
                hasMore={hasMoreNcns}
                loader={<DescriptionListSkeleton />}
                endMessage={
                  <p className="text-center text-gray-700 py-4">
                    End of records
                  </p>
                }
              >
                <TableChart
                  keys={tableKeys}
                  data={ncnData}
                  onClickRow={handleOnClickRow}
                  title="Non Conformance Overview"
                  settings={{
                    showConfig: false,
                    showPagination: false,
                  }}
                  onSortBy={handleSortTable}
                  sortBy={{
                    key: orderByRef.current,
                    direction: orderByDirectionRef.current,
                  }}
                />
              </InfiniteScroll>
            )}
          </Card>
        </div>
      </div>
    </main>
  );
};

function Filters({
  queryStatus,
  setQueryStatus,
  queryFieldStaff,
  setQueryFieldStaff,
  queryFarmCode,
  setQueryFarmCode,
  queryAuditDateFrom,
  setQueryAuditDateFrom,
  queryAuditDateTo,
  setQueryAuditDateTo,
  statusListOptions,
  fieldStaffListOptions,
  farmListOptions,
  formListOptions,
  setQueryAuditType,
  queryAuditType,
  className,
  onSubmit,
  ...other
}) {
  return (
    <div
      className={classNames(
        "grid grid-cols-2 tablet:grid-cols-3 gap-4",
        className
      )}
      {...other}
    >
      <div>
        <MultiSelect
          id="filter-farms"
          label="Farm(s)"
          labelPosition="inset"
          listOptions={farmListOptions}
          value={queryFarmCode}
          setValue={setQueryFarmCode}
          required={false}
          placeholder="Search by Farm Code"
          showSearch={true}
          disableCalcTrigger={true}
        />
      </div>
      <div>
        <MultiSelect
          id="filter-audit-type"
          label="Audit Type"
          labelPosition="inset"
          listOptions={formListOptions}
          value={queryAuditType}
          setValue={setQueryAuditType}
          required={false}
          placeholder="Search by Audit Type"
          showSearch={true}
          disableCalcTrigger={true}
        />
      </div>
      <div className="relative">
        <Datepicker
          id="filter-audit-date-from"
          label="Audit Date From"
          labelPosition="inset"
          placeholder="Search by Audit Date from"
          value={queryAuditDateFrom}
          setValue={setQueryAuditDateFrom}
          required={false}
          disableCalcTrigger={true}
        />
        {!isNullEmptyOrWhitespace(queryAuditDateFrom) && (
          <div
            className="absolute right-10 bottom-4 cursor-pointer group"
            onClick={() => setQueryAuditDateFrom("")}
          >
            <XIcon
              className="h-5 w-5 text-gray-400 group-hover:text-primary"
              title="clear value"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      <div className="relative">
        <Datepicker
          id="filter-audit-date-to"
          label="Audit Date To"
          labelPosition="inset"
          placeholder="Search by Audit Date to"
          value={queryAuditDateTo}
          setValue={setQueryAuditDateTo}
          required={false}
          disableCalcTrigger={true}
        />
        {!isNullEmptyOrWhitespace(queryAuditDateTo) && (
          <div
            className="absolute right-10 bottom-3.5 cursor-pointer group"
            onClick={() => setQueryAuditDateTo("")}
          >
            <XIcon
              className="h-5 w-5 text-gray-400 group-hover:text-primary"
              title="clear value"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      <div>
        <MultiSelect
          id="filter-status"
          label="Status"
          labelPosition="inset"
          listOptions={statusListOptions}
          value={queryStatus ?? statusListOptions[0].Value}
          setValue={setQueryStatus}
          required={false}
          disableCalcTrigger={true}
        />
      </div>
      <div>
        <MultiSelect
          id="filter-field-staff"
          label="Field Staff"
          labelPosition="inset"
          listOptions={fieldStaffListOptions}
          value={queryFieldStaff}
          setValue={setQueryFieldStaff}
          required={false}
          showSearch={true}
          disableCalcTrigger={true}
        />
      </div>
      <Button onClick={onSubmit} theme="primary">
        Apply Filter
      </Button>
    </div>
  );
}

export default ListPage;
