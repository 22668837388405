import { type IUser } from "helpers/formUtilities";
import { useCallback, useContext, useEffect, useState } from "react";
import useFetch from "hooks/useFetch";
import { UserContext } from "context/UserProvider";
import { deleteCookie, setCookie } from "helpers/storageUtilities";
import { authConfig, protectedResources } from "authConfig";
import { z } from "zod";

export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUser must be used within a AppDataProvider");
  }

  return context;
};

type useUserGetMeProps = {
  enabled?: boolean;
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};
export const useUserGetMe = ({
  enabled = true,
  onSuccess,
  onError,
}: useUserGetMeProps = {}) => {
  const { isLoading, isFetched, execute, error } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
    onSuccess: (response, responseBody) => {
      if (authConfig.mode === "b2c") {
        // Do nothing
        return onSuccess?.(response, responseBody);
      } else {
        const user = responseBody?.d;

        if (user) {
          // Renew the cookie
          setCookie("signedIn", "true", { expires: 30 }); // 30 days

          return onSuccess?.(response, responseBody);
        }
      }

      return onError?.(responseBody?.message);
    },
    onError,
  });

  const [data, setData] = useState<IUser | undefined>(undefined);

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/userme-get");

    const user = data?.d;

    setData(user);

    return user;
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, data, error, fetchData };
};

type useUserSyncADMeProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};
export const useUserSyncADMe = ({
  onSuccess,
  onError,
}: useUserSyncADMeProps = {}) => {
  // This is only for MSAL
  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.write,
    },
    onSuccess,
    onError,
  });

  const mutate = useCallback(
    async (user: {
      username: string;
      email: string;
      name?: string;
      // customerName?: string;
      // permissionLevel?: number;
      // permissionGroup?: string;
    }) => {
      const { data } = await execute("PUT", "/api/usersync-put", user);
      return data?.d;
    },
    [execute]
  );

  return { isLoading, isFetched, error, mutate };
};

export const isSuperUser = (user: IUser | undefined) => {
  if (!user) return false;

  return (
    user?.permissionGroupId?.toString() === "0" &&
    user?.permissionLevelId?.toString() === "0"
  );
};

type useUserGetManyProps = {
  enabled?: boolean;
};
export const useUserGetMany = ({
  enabled = true,
}: useUserGetManyProps = {}) => {
  const [data, setData] = useState<IUser[]>([]);

  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
  });

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/users-get");

    const users = data?.d ?? [];

    setData(users);

    return users;
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, data };
};

type useUserGetOneByIdProps = {
  enabled?: boolean;
  id: string;
};
export const useUserGetOneById = ({
  enabled = true,
  id,
}: useUserGetOneByIdProps) => {
  const [data, setData] = useState<IUser | undefined>(undefined);

  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
  });

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", `/api/user-get?id=${id}`);

    const user = data?.d;

    setData(user);

    return user;
  }, [execute, id]);

  useEffect(() => {
    if (enabled && !isFetched) {
      fetchData();
    }
  }, [enabled, fetchData, isFetched]);

  return { isLoading, isFetched, error, data };
};

type useUserGetOrgLevelsProps = {
  enabled?: boolean;
};
type orgLevelSchema = {
  id: number;
  name: string;
};
export const useUserGetOrgLevels = ({
  enabled = true,
}: useUserGetOrgLevelsProps = {}) => {
  const [data, setData] = useState<orgLevelSchema[]>([]);

  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
  });

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/userorglevels-get");

    const orgLevels = data?.d ?? [];

    setData(orgLevels);

    return orgLevels as orgLevelSchema[];
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, data };
};

type useUserGetPermissionGroupsProps = {
  enabled?: boolean;
};
type permissionGroupSchema = {
  id: number;
  name: string;
  farmAccessType: string;
};
export const useUserGetPermissionGroups = ({
  enabled = true,
}: useUserGetPermissionGroupsProps = {}) => {
  const [data, setData] = useState<permissionGroupSchema[]>([]);

  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
  });

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/userpermissiongroups-get");

    const permissionGroups = data?.d ?? [];

    setData(permissionGroups);

    return permissionGroups as permissionGroupSchema[];
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, data };
};

type useUserGetAssignedFarmsProps = {
  enabled?: boolean;
  id: string;
};
type assignedFarmSchema = {
  farmname: string;
  farmcode: string;
};
export const useUserGetAssignedFarms = ({
  enabled = true,
  id,
}: useUserGetAssignedFarmsProps) => {
  const [data, setData] = useState<assignedFarmSchema[]>([]);

  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
  });

  const fetchData = useCallback(async () => {
    const { data } = await execute(
      "GET",
      `/api/userassignedfarms-get?id=${id}`
    );

    const farms = data?.d ?? [];

    setData(farms);

    return farms as assignedFarmSchema[];
  }, [execute, id]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, data };
};

export const userCreateSchema = z
  .object({
    accountEnabled: z.boolean(),
    displayName: z.string(),
    emailAddress: z.string().email(),
    username: z
      .string()
      .min(3, "Username must be at least 3 characters.")
      .max(20, "Username must be at most 20 characters."),
    forceChangePasswordNextSignIn: z.boolean(),
    roleId: z.string(),
    assignedFarms: z.array(z.string()),
    password: z.string().min(8).optional(),
    confirmPassword: z.string().min(8).optional(),
    permissionLevelId: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.password && data.confirmPassword) {
        return data.password === data.confirmPassword;
      }

      return true;
    },
    { message: "Passwords do not match." }
  );
export type UserCreateSchema = z.infer<typeof userCreateSchema>;
export const userUpdateSchema = z
  .object({
    id: z.string().min(1, "User ID is required."),
    displayName: z.string(),
    emailAddress: z.string().email(),
    roleId: z.string(),
    permissionLevelId: z.string().optional(),
    assignedFarms: z.array(z.string()),
    password: z.string().optional(),
    confirmPassword: z.string().optional(),
  })
  .refine(
    (data) => {
      // if password provided ensure it meets the min and max length
      if (data.password) {
        return data.password.length >= 8 && data.password.length <= 20;
      }

      return true;
    },
    { message: "Password must be between 8 and 20 characters." }
  )
  .refine(
    (data) => {
      if (data.password && data.confirmPassword) {
        return data.password === data.confirmPassword;
      }

      return true;
    },
    { message: "Passwords do not match." }
  );
export type UserUpdateSchema = z.infer<typeof userUpdateSchema>;
type useUserMutateProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};
export const useUserMutate = ({
  onSuccess,
  onError,
}: useUserMutateProps = {}) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.write,
    },
    onSuccess,
    onError,
  });

  const mutate = useCallback(
    async (user: UserCreateSchema | UserUpdateSchema) => {
      if ("id" in user) {
        const { data } = await execute("PUT", "/api/user-put", user);
        return data?.d;
      } else {
        const { data } = await execute("POST", "/api/user-post", user);
        return data?.d;
      }
    },
    [execute]
  );

  return { isLoading, isFetched, error, mutate };
};

type useUserDeleteProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};
export const useUserDelete = ({
  onSuccess,
  onError,
}: useUserDeleteProps = {}) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.write,
    },
    onSuccess,
    onError,
  });

  const mutate = useCallback(
    async (id: string) => {
      const { data } = await execute("DELETE", "/api/user-delete", {
        id,
      });
      return data?.d;
    },
    [execute]
  );

  return { isLoading, isFetched, error, mutate };
};

type useUserSignInProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};
export const useUserSignIn = ({
  onSuccess,
  onError,
}: useUserSignInProps = {}) => {
  const { isLoading, error, execute } = useFetch({
    onSuccess: (response, responseBody) => {
      if (responseBody?.isAuthenticated) {
        setCookie("signedIn", "true", { expires: 30 }); // 30 days

        return onSuccess?.(response, responseBody);
      }

      return onError?.(responseBody?.message);
    },
    onError,
  });

  const mutate = useCallback(
    async (credentials: { username: string; password: string }) => {
      const { data } = await execute("POST", "/api/signin", {
        ...credentials,
      });

      return data?.d;
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

type useUserSignOutProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};
export const useUserSignOut = ({
  onSuccess,
  onError,
}: useUserSignOutProps = {}) => {
  const { execute } = useFetch({
    onSuccess: (response, responseBody) => {
      if (responseBody?.status === 200) {
        setUser(undefined);
        deleteCookie("signedIn");

        // clear all session storage
        sessionStorage.clear();

        return onSuccess?.(response, responseBody);
      }

      return onError?.(responseBody?.message);
    },
    onError,
  });
  const { setUser } = useUser();

  const mutate = useCallback(async () => {
    const { data } = await execute("POST", "/api/signout");

    return data;
  }, [execute]);

  return { mutate };
};

export const userFormSchema = z.object({
  legacyPermLevelOptions: z.array(
    z.object({ value: z.number(), text: z.string() })
  ),
});
export type UserFormSchema = z.infer<typeof userFormSchema>;
type useUserFormProps = {
  enabled?: boolean;
};
export const useUserForm = ({ enabled = true }: useUserFormProps = {}) => {
  const [data, setData] = useState<UserFormSchema | undefined>(undefined);

  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
  });

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/user-form-get");

    const userForm = data?.d;

    setData(userForm);

    return userForm;
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, data };
};
