import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { Menu, MenuItem, MenusContext } from "context/MenuProvider";
import { useLocation } from "react-router-dom";
import { parseJSON } from "helpers/stringUtilities";

export const useMenus = () => {
  const context = useContext(MenusContext);

  if (!context) {
    throw new Error("useMenus must be used within a AppDataProvider");
  }

  return context;
};

// TODO: this is currently not used as it relates to a new
// menu system structure that is not yet implemented
export const useMenuGetAll = ({
  enabled = true,
}: { enabled?: boolean } = {}) => {
  const { isLoading, isFetched, error, data } = useMenus();

  const fetchData = useCallback(async () => {
    return data;
  }, [data]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return {
    isLoading,
    isFetched,
    error,
    data,
  };
};

type useMenuGetOneProps = {
  enabled?: boolean;
  id: string;
};
export const useMenuGetOne = ({ enabled = true, id }: useMenuGetOneProps) => {
  const { isLoading, isFetched, error, data: menus } = useMenus();

  const [data, setData] = React.useState<Menu>();

  const fetchData = useCallback(async () => {
    const menu = menus?.find((m) => m.id === id);

    setData(menu);

    return menu;
  }, [id, menus]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return {
    isLoading,
    isFetched,
    error,
    data,
  };
};

type useMenuGetOneByNameProps = {
  enabled?: boolean;
  name: string;
};
export const useMenuGetOneByName = ({
  enabled = true,
  name,
}: useMenuGetOneByNameProps) => {
  const { isLoading, isFetched, error, data: menus } = useMenus();

  const [data, setData] = React.useState<Menu>();

  const fetchData = useCallback(async () => {
    const menu = menus?.find((m) => m.name === name);
    console.log("useMenuGetOneByName", name, menu);

    setData(menu);

    return menu;
  }, [menus, name]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return {
    isLoading,
    isFetched,
    error,
    data,
  };
};

export type ActiveMenuItem = MenuItem & {
  parent?: MenuItem;
  parsedMeta?: Record<string, any>;
};

function mutateMetaKeys(meta: Record<string, any>) {
  if (!meta) {
    return;
  }

  meta.parsedMeta = Object.keys(meta.parsedMeta).reduce((acc, key) => {
    acc[key.toLowerCase()] = meta.parsedMeta[key];
    return acc;
  }, {} as Record<string, any>);

  return meta;
}

// Function to recursively find the active menu item
const findActiveMenuItem = (
  menuItems: MenuItem[],
  pathname: string
): ActiveMenuItem | undefined => {
  for (const item of menuItems) {
    // Match the current path with the item's href
    if (item.href === pathname) {
      return item;
    }

    // If the item has children, search recursively
    if (item.children && item.children.length > 0) {
      const activeChild = findActiveMenuItem(item.children, pathname);
      if (activeChild) {
        // parse meta string to object
        if (item.meta) {
          activeChild.parsedMeta = parseJSON(item.meta);
          // convert all meta keys to lowercase
          mutateMetaKeys(activeChild.parsedMeta);
        }
        // set the parent
        activeChild.parent = item;

        return activeChild;
      }
    }
  }

  // Return undefined if no match is found
  return undefined;
};

// Hook to get the current active menu item
export const useActiveMenuItem = (menuName: string) => {
  const { data: menu } = useMenuGetOneByName({ name: menuName });
  const location = useLocation();
  const pathname = location.pathname;

  const activeMenuItem = useMemo(() => {
    if (!menu || !menu.menuItems) {
      return undefined;
    }

    return findActiveMenuItem(menu.menuItems, pathname);
  }, [menu, pathname]);

  return activeMenuItem;
};
