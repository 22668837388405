import { Alert, Input, MultiSelect, Select } from "components/core";
import { IDashboard } from "../types";
import { useMemo } from "react";
import { type IForm, type IListOption } from "helpers/formUtilities";
import { useMenuGetOneByName } from "hooks/useMenu";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";
import { useFarmGetGroups } from "hooks/useFarm";

interface IDashboardFormProps {
  dashboard: IDashboard | undefined;
  forms: IForm[];
  onChangeTitle: (title: string) => void;
  onChangeFarmGroups: (farmGroups: string) => void;
  onChangeDataSource: (datasource: string) => void;
  onChangeSortOrder: (sortOrder: number) => void;
  onChangeMenu: (menuId: string) => void;
}

export function DashboardForm(props: IDashboardFormProps) {
  const { data: menu } = useMenuGetOneByName({ name: "main" });

  const {
    isLoading: isLoadingFarmGroups,
    error: errorFarmGroups,
    farmGroups,
  } = useFarmGetGroups({
    enabled: true,
  });

  const datasourceListOptions = props.forms.map((form) => ({
    Id: form.FormName,
    Text: form.FormTitle,
    Value: form.FormName,
  }));

  const farmGroupListOptions = farmGroups?.map((fg) => ({
    Id: fg,
    Text: fg,
    Value: fg,
  }));

  // TODO: Implement user groups
  // const userGroupListOptions = userGroups.map((fg) => ({
  //   Id: fg,
  //   Text: fg,
  //   Value: fg,
  // }));

  const menuListOptions = useMemo(() => {
    const result: IListOption[] = [];
    if (!menu || !menu.menuItems) return result;

    for (const parentMenu of menu?.menuItems?.filter((m) => m.children)) {
      const children = parentMenu.children;
      if (!children) continue;

      for (const menu of children) {
        result.push({
          Id: menu.id,
          Text: `${parentMenu.title} > ${menu.title}`,
          Value: menu.id,
        });
      }
    }

    return result;
  }, [menu]);

  const isLoading = farmGroupListOptions === undefined;

  if (isLoading || isLoadingFarmGroups) {
    return (
      <div className="space-y-4 mt-4">
        <FieldsetSkeleton />
      </div>
    );
  }

  return (
    <div className="space-y-4 mt-4">
      {errorFarmGroups ? <Alert theme="danger">{errorFarmGroups}</Alert> : null}
      <Input
        id="title"
        label="Dashboard Title"
        placeholder="Enter a title for the dashboard"
        type="text"
        value={props.dashboard?.title ?? ""}
        labelPosition="top"
        setValue={props.onChangeTitle}
        disableCalcTrigger={true}
      />
      <Select
        id="datasource-select"
        label="Data Source"
        listOptions={datasourceListOptions}
        hint={undefined}
        value={props.dashboard?.dataSources ?? ""}
        setValue={props.onChangeDataSource}
        labelPosition="inset"
      />
      <Input
        id="sortOrder"
        label="Sort order"
        type="number"
        value={props.dashboard?.sortOrder}
        placeholder="0"
        labelPosition="top"
        setValue={props.onChangeSortOrder}
        disableCalcTrigger={true}
      />

      <div className="p-4 border border-gray-300 rounded-md space-y-4">
        <div className="text-sm font-medium">Filter by...</div>
        <p className="text-xs italic text-gray-500">
          The following selections will dictate where the dashboard will appear.
        </p>

        <MultiSelect
          label="Farm group(s)"
          id="farmgroups-multiselect"
          setValue={props.onChangeFarmGroups}
          value={props.dashboard?.farmGroups ?? ""}
          listOptions={farmGroupListOptions}
          disableCalcTrigger={true}
        />

        {menuListOptions?.length > 0 && (
          <Select
            label="Page(s)"
            id="menus-select"
            setValue={props.onChangeMenu}
            value={props.dashboard?.menuId ?? ""}
            listOptions={menuListOptions}
            disableCalcTrigger={true}
          />
        )}
      </div>
    </div>
  );
}
