import { type IUser } from "helpers/formUtilities";
import { useUserGetMe, useUserSyncADMe } from "hooks/useUser";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import cookie from "cookie";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useNetworkStatus from "hooks/useNetworkStatus";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { authConfig } from "authConfig.js";
import { isNullEmptyOrWhitespace } from "helpers/common";

export interface IUserContext {
  user: IUser | undefined;
  setUser: (user: IUser | undefined) => void;
  isSignedIn: boolean;
  isLoading: boolean;
}

type UserProviderProps = {
  children: React.ReactNode;
};

export const UserContext = createContext({} as IUserContext);

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const { accounts } = useMsal();
  const { isOnline } = useNetworkStatus();
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [isReadyToFetchUser, setIsReadyToFetchUser] = useState(false);

  const navigate = useNavigate();

  const {
    error: errorUser,
    data: userData,
    isLoading: isLoadingUser,
    isFetched: isFetchedUser,
  } = useUserGetMe({
    enabled: authConfig.mode === "b2c" ? isReadyToFetchUser : true,
    // onSuccess: (response, responseBody) => {
    //   console.log("Success getting user data");
    // },
    onError: (errMessage) => {
      // if (authConfig.mode === "b2c" && errMessage === "request not ready") {
      // }

      if (authConfig.mode === "legacy") {
        console.error("Error getting user data", errMessage, isFetchedUser);
        toast.error(errMessage ?? "Error getting user data.");

        navigate("/user/login");
      }
    },
  });

  const { mutate: mutateUserMe } = useUserSyncADMe({
    // No need to show the success message
    // onSuccess: (response, responseBody) => {
    //   const message = responseBody?.message ?? "User sync successfully.";
    //   toast.success(message);
    // },
    onError: (errMessage) => {
      toast.error(errMessage ?? "Error syncing user");
    },
  });

  const isAuthenticatedWithAD = useIsAuthenticated();

  const checkIsSignedIn = useCallback(() => {
    if (authConfig.mode === "b2c") {
      if (!isOnline) {
        /**
         * If offline & user is not null, then user is signed in
         */
        return !isNullEmptyOrWhitespace(user?.username);
      }

      if (isAuthenticatedWithAD && !isNullEmptyOrWhitespace(user?.username)) {
        return true;
      }

      return false;
    } else {
      // legacy
      const cookies = cookie.parse(document.cookie);
      const _isSignedInCookie = cookies.signedIn === "true";

      return _isSignedInCookie;
    }
  }, [isAuthenticatedWithAD, isOnline, user?.username]);

  const [isSignedIn, setIsSignedIn] = useState<boolean>(checkIsSignedIn());

  useEffect(() => {
    const isSignedIn = checkIsSignedIn();
    setIsSignedIn(isSignedIn);
  }, [checkIsSignedIn]);

  useEffect(() => {
    if (isSignedIn && user?.permissionGroupId === "-1") {
      console.error(user, user?.permissionGroupId);
      toast.warn(
        "You have limited permissions. If this is your first time logging in, please contact an administrator to request the necessary permissions.",
        {
          toastId: "user-permission-warning",
          autoClose: false,
        }
      );
    }
  }, [isSignedIn, user]);

  useEffect(() => {
    if (authConfig.mode !== "b2c") {
      return;
    }

    if (!navigator.onLine) {
      // deliberately not using isOnline here to avoid re-rendering
      setIsReadyToFetchUser(true);
      return;
    }

    if (accounts.length > 0) {
      // Assuming the first account is the one you're interested in
      // const userId = accounts[0].localAccountId;

      // Calling mutateUser will update/insert the user in the DB from the Azure AD data
      mutateUserMe({
        name: accounts[0].name,
        username: accounts[0].username,
        email: accounts[0].username,
        // customerName: undefined,
        // permissionLevel: undefined,
        // permissionGroup: undefined,
      }).then(() => {
        // Fetch the updated user data from the DB and set it in the context
        setIsReadyToFetchUser(true);
      });
    } else {
      setUser(undefined);
    }
  }, [accounts, mutateUserMe, setUser]);

  useEffect(() => {
    if (errorUser) {
      console.error(errorUser);
    }
  }, [errorUser]);

  useEffect(() => {
    setUser(userData);
  }, [setUser, userData]);

  const contextValue = useMemo(
    () => ({ user, setUser, isSignedIn, isLoading: isLoadingUser }),
    [user, setUser, isSignedIn, isLoadingUser]
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
