import React from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  rolePermissionMutateSchema,
  RolePermissionMutateSchema,
  usePermissionGetOne,
  useRolePermissionMutate,
} from "hooks/useRole";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Alert } from "components/core";
import Card from "components/Card";
import Button from "components/placement/Button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/placement/Form";
import { Input } from "components/placement/Input";
import { isNullEmptyOrWhitespace } from "helpers/common";
import { ErrorMessage } from "@hookform/error-message";

const RolePermissionForm = ({
  permissionId,
  roleId,
}: {
  permissionId: string | undefined;
  roleId: string;
}) => {
  const navigate = useNavigate();
  const isNewPermission = isNullEmptyOrWhitespace(permissionId);

  const {
    data: permissionData,
    isLoading: isLoadingPermission,
    isFetched: isFetchedPermission,
  } = usePermissionGetOne({
    enabled: !isNewPermission,
    id: permissionId ?? "",
  });

  const { mutate } = useRolePermissionMutate({
    onSuccess: () => {
      toast.success("Permission updated successfully.");

      navigate("./..");
    },
    onError: (errMessage) => {
      toast.error(errMessage);
    },
  });

  const reactForm = useForm<RolePermissionMutateSchema>({
    defaultValues: permissionData ?? {
      roleId: roleId,
      id: permissionId,
      name: "",
      group: "",
    },
    resolver: zodResolver(rolePermissionMutateSchema),
  });
  const resetForm = reactForm.reset;

  React.useEffect(() => {
    if (permissionData) {
      const newRolePermissionData = {
        ...permissionData,
        roleId: roleId,
      };
      resetForm(newRolePermissionData);
    }
  }, [permissionData, resetForm, roleId]);

  if (!isNewPermission && (isLoadingPermission || !isFetchedPermission)) {
    return <FieldsetSkeleton />;
  }

  if (!isNewPermission && !permissionData) {
    return <Alert theme="danger">Permission not found.</Alert>;
  }

  if (permissionData?.group === "legacypage") {
    return (
      <Alert theme="danger">
        Legacy page permissions cannot be edited. Please contact the system
        administrator for assistance.
      </Alert>
    );
  }

  const onSubmit = async (data: RolePermissionMutateSchema) => {
    console.log("data", data);
    await mutate(data);
  };

  const handleClickCancel = () => {
    navigate("./..");
  };

  return (
    <Form {...reactForm}>
      <form
        className="mt-4 space-y-4"
        onSubmit={reactForm.handleSubmit(onSubmit)}
        noValidate
      >
        <Card>
          {/* <CardTitle className="mb-4">{isNewPermission ? "Create" : "Edit"} Role Permission</CardTitle> */}
          <div className="space-y-4">
            <FormField
              control={reactForm.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="main:farm:dashboards" />
                  </FormControl>
                  <FormDescription className="text-xs text-gray-500">
                    The system name of the permission. This is used to check if
                    a user has a specific permission and must be
                    <span className="italic font-medium">unique</span>.
                    <br />
                    E.g. '
                    <span className="font-mono italic">
                      main:farm:dashboards
                    </span>
                    ' (menu_name:module_name:page_name) for page permissions or
                    '<span className="font-mono italic">user:create</span>'
                    (module_name:access_name) for api permissions.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={reactForm.control}
              name="group"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Group</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="page" />
                  </FormControl>
                  <FormDescription className="text-xs text-gray-500">
                    The group of the permission. This is used to group
                    permissions together for easier management.
                    <br />
                    E.g. 'page' for page permissions or 'api' for api
                    permissions.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </Card>
        <ErrorMessage
          errors={reactForm.formState.errors}
          name="id"
          render={({ message }) => (
            <div className="text-danger-500 text-sm">{message}</div>
          )}
        />
        <div className="flex justify-between">
          <Button
            type="button"
            variant="default"
            size="sm"
            onClick={handleClickCancel}
          >
            Cancel
          </Button>
          <div className="space-x-4">
            <Button type="submit" variant="primary">
              Save
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};

export default RolePermissionForm;
