import Card from "components/Card";
import { tryInvalidateApiCache } from "helpers/common";
import { useRoleDelete, useRoleGetAll } from "hooks/useRole";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const RoleList = () => {
  const {
    data: roles,
    isLoading: isLoadingRoles,
    refetch: refetchRoles,
  } = useRoleGetAll();

  const { mutate: deleteRoleMutate } = useRoleDelete({
    onSuccess: () => {
      toast.success("Role deleted successfully.");

      tryInvalidateApiCache("/api/roles-get");
      refetchRoles();
    },
    onError: (errMessage) => {
      toast.error(errMessage);
    },
  });

  if (isLoadingRoles) return <div>Loading roles...</div>;

  const handleClickDeleteRole = (id: string) => {
    deleteRoleMutate(id);
  };

  return (
    <Card>
      <div className="">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="font-semibold py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 tablet:pl-0"
              >
                Name
              </th>
              <th
                scope="col"
                className="font-semibold hidden px-3 py-3.5 text-left text-sm text-gray-900 tablet:table-cell desktop:table-cell"
              >
                Description
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 tablet:pr-0">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {!!roles ? (
              roles.map((role) => (
                <tr key={role.id}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 tablet:w-auto tablet:max-w-none tablet:pl-0">
                    <Link to={`${role.id}`}>{role.name}</Link>
                    <dl className="font-normal tablet:hidden">
                      <dt className="sr-only">Description</dt>
                      <dd className="mt-1 truncate text-gray-700">
                        {role.description}
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 tablet:table-cell">
                    {role.description}
                  </td>
                  <td className="py-4 pl-3 pr-4 text-right text-sm font-medium tablet:pr-0 inline-flex space-x-2">
                    <Link
                      to={`${role.id}`}
                      className="text-primary hover:text-primary-dark"
                    >
                      Edit<span className="sr-only">, {role.name}</span>
                    </Link>
                    <Link
                      to="#"
                      className="text-primary text-sm"
                      onClick={() => handleClickDeleteRole(role.id)}
                    >
                      Delete
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="">
                  No roles found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default RoleList;
